import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Hidden } from "react-grid-system";

const AnimatedBall = ({ color = "#8211d8", top = undefined, left = "25%" }) => {
	const [show, setShow] = useState(true);
	useEffect(() => {
		const interval = setInterval(() => {
			setShow(!show);
		}, 800);
		return () => clearInterval(interval);
	}, [show]);
	return (
		<Hidden sm xs md>
			<div
				style={{
					width: "36px",
					height: "36px",
					position: "absolute",
					borderRadius: "50%",
					backgroundColor: show ? color : "transparent",
					transition: "all ease-out 0.5s",
					top: top !== undefined && top,
					left: left,
					zIndex: "999",
				}}
			></div>
		</Hidden>
	);
};

export default AnimatedBall;
