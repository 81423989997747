import React from "react";
import Header from "./Landing/Header";

const Layout = ({ children }) => {
	return (
		<div
			style={{
				overflow: "hidden",
			}}
		>
			<Header />
			<div>{children}</div>
		</div>
	);
};

export default Layout;
