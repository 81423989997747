import React from "react";
import { Col, Container, Row } from "react-grid-system";
import MerchantHeader from "../MerchantHeader";
import { FileDoneOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const ImportStatus = () => {
	return (
		<div
			style={{
				backgroundColor: "#d9d8d9",
				position: "relative",
				overflow: "hidden",
			}}
		>
			<MerchantHeader />
			<Container
				style={{ paddingTop: "100px", zIndex: "1", minHeight: "100vh" }}
			>
				<Row>
					<Col
						lg={8}
						xl={8}
						style={{
							padding: "0 15px",
							backgroundColor: "#fff",
							borderRadius: "10px",
						}}
						className="create-parcel"
					>
						<div
							style={{
								display: "flex",
								alignItems: "end",
								justifyContent: "space-between",
							}}
						>
							<div>
								<h4
									style={{
										fontSize: "28px",
										fontWeight: "bold",
										lineHeight: "25px",
										margin: "0",
									}}
								>
									Bulk upload import status
								</h4>
								<p>Heres a summary of all the parcels for bulk upload</p>
							</div>
							<button
								style={{
									backgroundColor: "transparent",
									fontWeight: "600",
									fontSize: "18px",
									border: "none",
									color: "#af2300",
									cursor: "pointer",
								}}
							>
								Refresh to update parcel
							</button>
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								gap: "10px",
								padding: "15px 0",
								width: "100%",
							}}
						>
							<div
								style={{
									padding: "5px 15px",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									width: "100%",
									border: "1px solid gray",
									borderRadius: "8px",
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "start",
										gap: "20px",
									}}
								>
									<div>
										<FileDoneOutlined style={{ fontSize: "45px" }} />
									</div>
									<div>
										<p style={{ fontSize: "25px", margin: "0" }}>Title</p>
										<p>description here</p>
										<button
											style={{
												backgroundColor: "transparent",
												fontWeight: "600",
												fontSize: "18px",
												border: "none",
												color: "#af2300",
												cursor: "pointer",
											}}
										>
											View Parcel
										</button>
									</div>
								</div>
								<div>
									<button
										style={{
											backgroundColor: "transparent",
											fontWeight: "600",
											fontSize: "18px",
											border: "none",
											color: "#af2300",
											cursor: "pointer",
										}}
									>
										Download print label
									</button>
								</div>
							</div>
							<div
								style={{
									padding: "5px 15px",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									width: "100%",
									border: "1px solid gray",
									borderRadius: "8px",
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "start",
										gap: "20px",
									}}
								>
									<div>
										<FileDoneOutlined style={{ fontSize: "45px" }} />
									</div>
									<div>
										<p style={{ fontSize: "25px", margin: "0" }}>Title</p>
										<p>description here</p>
										<button
											style={{
												backgroundColor: "transparent",
												fontWeight: "600",
												fontSize: "18px",
												border: "none",
												color: "#af2300",
												cursor: "pointer",
											}}
										>
											View Parcel
										</button>
									</div>
								</div>
								<div>
									<button
										style={{
											backgroundColor: "transparent",
											fontWeight: "600",
											fontSize: "18px",
											border: "none",
											color: "#af2300",
											cursor: "pointer",
										}}
									>
										Download print label
									</button>
								</div>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								paddingTop: "30px",
							}}
						>
							<Link
								to="/merchant/parcels"
								style={{
									border: "1px solid gray",
									padding: "5px 10px",
									borderRadius: "8px",
									backgroundColor: "#f9f9f9",
								}}
							>
								Go to Parcels Page
							</Link>
							<Link
								to="/merchant/create-parcel"
								style={{
									border: "1px solid gray",
									padding: "5px 10px",
									borderRadius: "8px",
									backgroundColor: "#f9f9f9",
								}}
							>
								Create New Parcel
							</Link>
						</div>
					</Col>
					<Col lg={4} xl={4}>
						<div
							style={{
								backgroundColor: "white",
								padding: "15px 25px",
								borderRadius: "8px",
							}}
						>
							<h4 style={{ fontSize: "25px", fontWeight: "bold" }}>
								Did you know?
							</h4>
							<div
								style={{
									padding: "15px 0",
								}}
							>
								<h5>16%</h5>
								<p>
									Reduction in the chances of parcel loss by pasting the sticker
									on a parcel before pickup
								</p>
							</div>
							<div
								style={{
									padding: "15px 0",
								}}
							>
								<h5>87.5%</h5>
								<p>
									Reduction in pickup hub's parcel receiving, which means we can
									serve you better by processing more parcels!
								</p>
							</div>
							<div
								style={{
									border: "1px solid gray",
									padding: "10px",
									borderRadius: "6px",
								}}
							>
								<h5 style={{ fontSize: "22px" }}>Guidelines</h5>
								<ol>
									<li style={{ margin: "10px 0", fontSize: "17px" }}>
										<span
											style={{
												color: "#af2300",
												backgroundColor: "#ffc4c4",
												fontWeight: "bold",
												fontSize: "18px",
												padding: "6px 10px",
												borderRadius: "50%",
											}}
										>
											1
										</span>{" "}
										Avoid tissue packaging
									</li>
									<li style={{ margin: "10px 0", fontSize: "17px" }}>
										<span
											style={{
												color: "#af2300",
												backgroundColor: "#ffc4c4",
												fontWeight: "bold",
												padding: "6px 10px",
												borderRadius: "50%",
												fontSize: "18px",
											}}
										>
											2
										</span>{" "}
										Write last 4 digits of customer phone number on the parcel
										so that it is easier to segregate the parcel
									</li>
								</ol>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ImportStatus;
