import React from "react";
import { Link } from "react-router-dom";

const AdPoster = ({ className, link, linkTitle }) => {
	return (
		<div>
			<div
				className={className}
				style={{
					width: "100%",
					margin: "15px auto",
					padding: "120px 20px",
					borderRadius: "12px",
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					zIndex: "11",
				}}
			>
				<Link
					to={link}
					style={{
						color: "white",
						backgroundColor: "#7030a0",
						padding: "5px 20px",
						fontSize: "22px",
						borderRadius: "10px",
						boxShadow: "2px 5px 15px gray",
					}}
				>
					{linkTitle}
				</Link>
			</div>
		</div>
	);
};

export default AdPoster;
