import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../App.css";
import { motion } from "framer-motion";

import { MdMenu } from "react-icons/md";

import logo from "../../images/Logo Png 2 (White).png";
import {
	Col,
	Container,
	Hidden,
	Row,
	useScreenClass,
	Visible,
} from "react-grid-system";
import { LogoutAction } from "../../redux/actions/authAction";
import { useDispatch } from "react-redux";

const leftMenuItems = [
	{
		title: "services",
		key: "services",
		id: "#services",
	},
	{
		title: "coverage",
		key: "coverage",
		id: "#coverage",
	},
	{
		title: "pricing",
		key: "pricing",
		id: "#pricing",
	},
	{
		title: "reviews",
		key: "reviews",
		id: "#reviews",
	},
	{
		title: "help",
		key: "help",
	},
];

const MenuItem = ({ item, activeNav, setActiveNav, visible, setVisible }) => {
	//Menu Link click handler
	const handleClick = (item) => {
		item.id ? setActiveNav(item.id) : setActiveNav(item.key);
		setVisible(!visible);
	};
	return (
		<a
			href={item.id ? `/${item.id}` : `/${item.key}`}
			style={{
				fontSize: "20px",
				padding: "8px 5px 3px 5px",
				margin: "0 5px",
				color: `${item.color || "white"}`,
				fontWeight: "bold",
				borderBottom:
					item.id && activeNav === item.id
						? "8px solid #45ff9d"
						: "8px solid transparent",
			}}
			onClick={() => handleClick(item)}
		>
			{item.title}
		</a>
	);
};

const Header = () => {
	const [visible, setVisible] = useState(false);
	const [isLogoutDropdown, setIsLogoutDropdown] = useState(false);
	const screenClass = useScreenClass();
	const [activeNav, setActiveNav] = useState("#home");
	const { pathname, hash } = useLocation();
	const user = JSON.parse(localStorage.getItem("user"));
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(LogoutAction());
	};

	return (
		<div>
			{/*pathname === "/" && (
				<div
					style={{
						width: "100%",
						padding: "5px 0",
						backgroundColor: "#00a84e",
						color: "#fff",
					}}
				>
					<Container>top nav</Container>
				</div>
				) */}
			<motion.nav
				initial={{ y: "-50%" }}
				animate={{ y: "0" }}
				transition={{ duration: 1 }}
				className="navbar"
			>
				{pathname === "/" && (
					<Hidden sm xs md>
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{ duration: 1.5 }}
							style={{
								position: "absolute",
								top: pathname === "/" ? "-45%" : "-45%",
								left: "2%",
								height: "120px",
								width: "120px",
								backgroundColor: "#8211d8",
								borderRadius: "50%",
							}}
						></motion.div>
					</Hidden>
				)}
				<Container className="nav">
					<Row
						style={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent:
								pathname === "/" && hash.length === 0 ? "center" : "flex-end",
							padding:
								pathname === "/" && hash.length === 0 ? "10px 0 35px 0" : " 0",
						}}
					>
						<Col>
							<div
								className={`${activeNav === "#home" && "active-nav"} logo`}
								onClick={() => setActiveNav("#home")}
							>
								<Link to="/">
									<img
										src={logo}
										alt="rapido"
										className="logo-img"
										style={{
											width: ["lg", "xl", "xxl"].includes(screenClass)
												? "200px"
												: "180px",
										}}
									/>
								</Link>
							</div>
						</Col>
						<Hidden xs sm md>
							<Col>
								<div
									className="menu"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										gap: "100px",
									}}
								>
									<div
										className="left-menu"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											gap: "25px",
										}}
									>
										{leftMenuItems.map((item) => (
											<MenuItem
												item={item}
												key={item.key}
												activeNav={activeNav}
												setActiveNav={setActiveNav}
												pathname={pathname}
											/>
										))}
									</div>
									<div
										className="right-menu"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											gap: "20px",
											color: "white",
										}}
									>
										<div
											style={{
												color: "#45ff9c",
												fontSize: "20px",
												backgroundColor: "#5b0a82",
												fontWeight: "bold",
												padding: "4px 8px",
												borderRadius: "10px",
											}}
										>
											{user ? (
												<div style={{ position: "relative" }}>
													<div
														style={{ cursor: "pointer" }}
														onClick={() =>
															setIsLogoutDropdown(!isLogoutDropdown)
														}
													>
														<span>{user.name}</span>
													</div>
													{isLogoutDropdown && (
														<div
															style={{
																position: "absolute",
																top: "35px",
																left: "-10px",
																width: "180px",
																backgroundColor: "#5b0a82",
																padding: "15px 8px",
																borderRadius: "12px",
															}}
														>
															<div>
																<Link
																	to={
																		user?.user_type === 1
																			? "/admin/"
																			: "/merchant/dashboard"
																	}
																	style={{ color: "#45ff9c" }}
																>
																	Dashboard
																</Link>
															</div>
															<div style={{ padding: "12px 0" }}>
																<Link
																	to="/user-profile"
																	style={{ color: "#45ff9c" }}
																>
																	View Profile
																</Link>
															</div>
															<div>
																<p
																	style={{
																		cursor: "pointer",
																	}}
																	onClick={() => handleLogout()}
																>
																	Logout
																</p>
															</div>
														</div>
													)}
												</div>
											) : (
												<Link to="/login" style={{ color: "#45ff9c" }}>
													Login
												</Link>
											)}
										</div>
										<div>EN</div>
									</div>
								</div>
							</Col>
						</Hidden>

						<Visible xs sm md>
							<Col>
								<div
									onClick={() => {
										setVisible(!visible);
									}}
									style={{
										color: "white",
										fontSize: "25px",
										textAlign: "right",
									}}
								>
									<MdMenu />
								</div>
							</Col>
						</Visible>
					</Row>
					{visible && (
						<Row>
							<Col>
								<div
									className="menu"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										flexDirection: "column",
										position: "absolute",
										top: "0",
										left: "0",
										width: "100%",
										backgroundColor: "black",
									}}
								>
									<div
										className="left-menu"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											flexDirection: "column",
										}}
									>
										{leftMenuItems.map((item) => (
											<MenuItem
												item={item}
												key={item.key}
												visible={visible}
												setVisible={setVisible}
											/>
										))}
									</div>
									<div
										className="right-menu"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											gap: "40px",
											paddingBottom: "15px",
										}}
									>
										<div
											style={{
												color: "#45ff9c",
												fontSize: "20px",
												backgroundColor: "#5b0a82",
												fontWeight: "bold",
												padding: "4px 8px",
												borderRadius: "10px",
											}}
										>
											{user ? (
												<div style={{ position: "relative" }}>
													<div
														style={{ cursor: "pointer" }}
														onClick={() =>
															setIsLogoutDropdown(!isLogoutDropdown)
														}
													>
														<span>{user.name}</span>
													</div>
													{isLogoutDropdown && (
														<div
															style={{
																position: "absolute",
																top: "35px",
																left: "-10px",
																width: "180px",
																backgroundColor: "#5b0a82",
																padding: "15px 8px",
																borderRadius: "12px",
															}}
														>
															<Link
																to="/user-profile"
																style={{ color: "#45ff9c" }}
															>
																View Profile
															</Link>
															<p
																style={{
																	cursor: "pointer",
																	paddingTop: "10px",
																}}
																onClick={() => handleLogout()}
															>
																Logout
															</p>
														</div>
													)}
												</div>
											) : (
												<Link to="/login" style={{ color: "#45ff9c" }}>
													Login
												</Link>
											)}
										</div>
										<div style={{ color: "white" }}>EN</div>
									</div>
								</div>
							</Col>
						</Row>
					)}
				</Container>
			</motion.nav>
		</div>
	);
};

export default Header;
