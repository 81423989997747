import React from "react";
import { Col, Container, Hidden, Row } from "react-grid-system";
import "../../App.css";
import { useScreenClass } from "react-grid-system";
import { motion } from "framer-motion";

//import Icons
// import { MdNotListedLocation } from "react-icons/md";

//import Images
import deliveryMan from "../../images/banner/banner-inner.png";
import bannerOne from "../../images/banner/banner-1.gif";
import bannerTwo from "../../images/banner/bannerinner-bg.gif";
// import innerBg from "../images/banner/bannerInnerBg.png";
import trackingBg from "../../images/banner/tracking-bg.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import mascot from "../../images/banner/mascot.png";

const bannerGifImages = [bannerOne, bannerTwo];

const Banner = () => {
	const screenClass = useScreenClass();
	const [activeBackground, setActiveBackground] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setActiveBackground((v) => {
				return v === 1 ? 0 : v + 1;
			});
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<section
			id="home"
			className="banner-full"
			style={{
				width: "100%",
				// height: ["lg", "xl", "xxl", "md"].includes(screenClass) && "90vh",
				// height: "100%",
				backgroundColor: "black",
				overflow: "hidden",
				paddingTop: "110px",
			}}
		>
			<div
				style={{
					// height: ["lg", "xl", "xxl"].includes(screenClass) && "70%",
					width: "100%",
				}}
			>
				<div
					style={{
						position: "relative",
						height: "100%",
						width: "100%",
						backgroundImage: `url(${bannerGifImages[activeBackground]})`,
						backgroundColor: activeBackground === 0 ? "white" : "#fafee8",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: " 40% 70%",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: ["lg", "xl", "xxl"].includes(screenClass)
							? "293px 0"
							: "40px 0",
						borderRadius: "100px 0 0 0",
					}}
				>
					<Hidden sm xs md>
						<img
							src={deliveryMan}
							alt="delivery man"
							style={{
								position: "absolute",
								// top: "28.8%",
								bottom: "-4px",
								left: "5%",
								width: "30%",
							}}
						/>
					</Hidden>
					{/* START ----track my pack inner box -----START*/}
					<motion.div
						initial={{ right: "-150%" }}
						animate={{ right: "-5%" }}
						transition={{ type: "spring", stiffness: 260, damping: 80 }}
						className="track-pack"
						style={{
							maxWidth: "600px",
							maxHeight: "550px",
							width: ["lg", "xl", "xxl"].includes(screenClass) ? "32%" : "90%",
							height: ["lg", "xl", "xxl"].includes(screenClass) ? "72%" : "90%",
							backgroundImage: `url(${trackingBg})`,
							backgroundPosition: "top left",
							backgroundRepeat: "no-repeat",
							position: ["lg", "xl", "xxl"].includes(screenClass)
								? "absolute"
								: "static",
							top: "-3%",
							right: "-5%",
							// zIndex: "9999",
							paddingTop: ["lg", "xl", "xxl"].includes(screenClass)
								? "120px"
								: "400px",
							paddingBottom: ["lg", "xl", "xxl"].includes(screenClass)
								? "0"
								: "30px",
							paddingLeft: "30px",
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "center",
							flexDirection: "column",
							gap: "40px",
							margin: "0 auto",
						}}
					>
						<form
							action=""
							style={{
								display: "flex",
								alignItems: "flex-end",
								justifyContent: "flex-start",
								flexDirection: "column",
								gap: "8px",
								marginTop: "150px",
							}}
						>
							<input
								type="text"
								name="track"
								id="track"
								style={{
									borderRadius: "8px",
									// borderRadius: ["lg", "xl", "xxl", "md"].includes(screenClass)
									// 	? "8px 0 0 8px"
									// 	: "8px",
									border: "none",
									fontSize: ["lg", "xl", "xxl", "md"].includes(screenClass)
										? "20px"
										: "15px",
									padding: ["lg", "xl", "xxl", "md"].includes(screenClass)
										? "8px 10px"
										: "8px",
								}}
							/>
							<input
								type="submit"
								value="track"
								style={{
									fontSize: "20px",
									fontWeight: "bold",
									// borderRadius: ["lg", "xl", "xxl", "md"].includes(screenClass)
									// 	? "0 8px 8px 0"
									// 	: "8px",
									borderRadius: "8px",
									padding: ["lg", "xl", "xxl", "md"].includes(screenClass)
										? "8px 15px"
										: "5px 8px",
									backgroundColor: "#7030a0",
									color: "white",
									border: "none",
									cursor: "pointer",
								}}
							/>
						</form>
					</motion.div>
					{/*END ---- track my pack inner box ---END */}
					{/*START ---- Mascot & round ---START */}
					<Hidden sm md xs>
						<motion.div
							initial={{ scale: 0 }}
							animate={{ rotate: 180, scale: 1 }}
							transition={{
								type: "spring",
								stiffness: 260,
								damping: 20,
							}}
							className="mascot-round"
							style={{
								width: "320px",
								height: "320px",
								backgroundColor: "#51d379",
								borderRadius: "50%",
								position: "absolute",
								bottom: "-32%",
								right: "-5%",
							}}
						></motion.div>
						<motion.img
							initial={{ right: "-150%" }}
							animate={{ right: "0%" }}
							transition={{ type: "spring", stiffness: 260, damping: 40 }}
							src={mascot}
							alt="mascot"
							style={{
								width: "10%",
								position: "absolute",
								right: "-0%",
								bottom: "-37%",
							}}
						/>
					</Hidden>
					{/*END ---- Mascot & round ---END */}
				</div>
			</div>

			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 2 }}
				style={{ backgroundColor: "black", paddingBottom: "25px" }}
			>
				<Container>
					<Row className="banner-bottom" align="center" justify="start">
						<Col md={5.5} sm={12}>
							<p
								style={{
									fontSize: "20px",
									lineHeight: "30px",
									color: "white",
									padding: "20px 0",
								}}
							>
								Your business deserves a customized & personalized delivery
								service.
								<span style={{ color: "#04d643" }}> Rapido</span> is a 360’
								e-commerce home delivery service that’s let’s you do business
								from the comfort of your home.
							</p>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									color: "white",
									padding: "15px 0",
									flexDirection: ["lg", "xl", "xxl", "md"].includes(screenClass)
										? "row"
										: "column",
									gap: "12px",
								}}
							>
								<h2
									style={{
										fontWeight: "400",
										fontSize: "25px",
										color: "white",
									}}
								>
									Grow your Business, Today!
								</h2>
								<Link
									to="/register"
									style={{
										backgroundColor: "#4D1472",
										color: "white",
										fontSize: "25px",
										fontWeight: "bold",
										padding: "8px 15px",
										border: "none",
										borderRadius: "10px",
										cursor: "pointer",
									}}
								>
									Register
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</motion.div>
		</section>
	);
};

export default Banner;
