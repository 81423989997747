import React from "react";

const ImportOrder = () => {
	return (
		<div>
			<h2>Import Order</h2>
		</div>
	);
};

export default ImportOrder;
