import React, { useState } from "react";
import SingleOrderForm from "./SingleOrderForm";

const SingleOrder = ({ ordersArr, setOrdersArr }) => {
	const [isAgree, setIsAgree] = useState(false);

	//Submit single orders overall form to to api
	const handleSubmit = () => {
		console.log(ordersArr);
	};
	return (
		<div>
			{ordersArr.map((item, index) => (
				<SingleOrderForm
					key={index}
					item={item}
					index={index}
					isShow={true}
					ordersArr={ordersArr}
					setOrdersArr={setOrdersArr}
				/>
			))}
			<SingleOrderForm ordersArr={ordersArr} setOrdersArr={setOrdersArr} />
			<div style={{ display: "flex", gap: "15px", padding: "35px 0" }}>
				<input
					type="checkbox"
					name="check"
					id="check"
					onChange={() => setIsAgree(!isAgree)}
				/>
				<label htmlFor="check">
					I agree all the details provided above are true.
				</label>
			</div>
			<div className="create-order-button-wrapper">
				<button className="cancel">cancel</button>
				<button
					className={`${isAgree ? "place-order" : "disable"}`}
					disabled={!isAgree}
					onClick={handleSubmit}
				>
					place order
				</button>
			</div>
		</div>
	);
};

export default SingleOrder;
