import React from "react";
import "../../App.css";
import { motion } from "framer-motion";
// Import Swiper Components & styles
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

//import images for slider
import reviewImgSam from "../../images/review/Review Pictures/Sam Afrin.jpg";
import reviewImgAlmari from "../../images/review/Review Pictures/Almaari Clothing (client).jpg";
import reviewImgLapromesa from "../../images/review/Review Pictures/Lapromesa.jpg.jpg";
import reviewFullBg from "../../images/review/6 5fbd5f55328639.5a12b9089b658.gif";
import { Container, useScreenClass } from "react-grid-system";

const reviewList = [
	{
		key: "sam",
		name: "Sam Afrin Sheik",
		img: reviewImgAlmari,
		designation: "Founder",
		company: "Mommy Diaries BD",
		content:
			"I have been a recurring user of Rapido's services. We first got in contact with them almost 5 or 6 years back when we needed delivery services to send gifts for an event.Their system is quite simple. I register on their website and select my order form and fill in the orders. Then I leave them a message on facebook about the orders. They are usually always on time.",
	},
	{
		key: "almaari",
		name: "Shompa Haque",
		img: reviewImgSam,
		designation: "Founder",
		company: "Lapromesa",
		content:
			"I have been a recurring user of Rapido's services. We first got in contact with them almost 5 or 6 years back when we needed delivery services to send gifts for an event. Their system is quite simple. I register on their website and select my order form and fill in the orders. Then I leave them a message on facebook about the orders. They are usually always on time.",
	},
	{
		key: "lap",
		name: "Mehnaz Jahan",
		img: reviewImgLapromesa,
		designation: "Founder",
		company: "Fashion Vector",
		content:
			"I have been working with Rapido for a couple of years..They have improved a lot  day by day. I genuinely recommend them. They are trustworthy. ",
	},
];

const Review = () => {
	const screenClass = useScreenClass();
	return (
		<section
			id="reviews"
			className="review-full"
			style={{
				width: "100%",
				// height: ["lg", "xl", "xxl"].includes(screenClass) && "90vh",
				backgroundImage: `url(${reviewFullBg})`,
				backgroundPosition: "top left",
				backgroundRepeat: "no-repeat",
				backgroundSize: ["lg", "xl", "xxl"].includes(screenClass)
					? // ? "97% 120%"
					  "97% 120%"
					: "cover",
			}}
		>
			<div
				className="review-bg"
				style={{
					position: "relative",
					backgroundSize: ["lg", "xl", "xxl"].includes(screenClass)
						? // ? "80% 140%"
						  "80% 130%"
						: "0%",
					backgroundPosition: ["lg", "xl", "xxl"].includes(screenClass)
						? "center"
						: "top left",
					backgroundRepeat: ["lg", "xl", "xxl"].includes(screenClass)
						? "repeat-x"
						: "repeat-y",
				}}
			>
				<motion.h2
					initial={{ x: "-150%" }}
					whileInView={{ x: 0 }}
					transition={{ delay: 0.5 }}
					style={{
						textTransform: "uppercase",
						color: "white",
						position: ["lg", "xl", "xxl"].includes(screenClass)
							? "absolute"
							: "static",
						fontSize: ["lg", "xl", "xxl"].includes(screenClass)
							? "80px"
							: "60px",
						top: "22%",
						left: "10%",
						letterSpacing: "3px",
						textAlign: "center",
						padding: ["lg", "xl", "xxl"].includes(screenClass) ? "0" : "50px 0",
					}}
				>
					Reviews
				</motion.h2>
				<div
					className=""
					style={{
						paddingTop: ["lg", "xl", "xxl"].includes(screenClass)
							? "320px"
							: "40px",
						paddingBottom: "30px",
						margin: "0 auto",
					}}
				>
					<Swiper
						modules={[Navigation, Pagination]}
						spaceBetween={0}
						slidesPerView={1}
						navigation={true}
						pagination={{ clickable: true }}
						// style={{ paddingBottom: "30px" }}
					>
						{reviewList.map((review) => (
							<SwiperSlide
								key={review.key}
								style={{
									backgroundColor: ["lg", "xl", "xxl"].includes(screenClass)
										? "transparent"
										: "white",
									textAlign:
										["md", "xs", "sm"].includes(screenClass) && "center",
									borderRadius: "50px",
								}}
							>
								<Container>
									<div
										style={{
											width: ["lg", "xl", "xxl"].includes(screenClass)
												? "70%"
												: "100%",
											margin: "0 auto",
										}}
									>
										<motion.img
											initial={{ opacity: 0 }}
											whileInView={{ opacity: 1 }}
											transition={{ delay: 0.5 }}
											src={review.img}
											alt={review.name}
											style={{
												borderRadius: "50%",
												width: "250px",
												height: "250px",
												marginBottom: ["lg", "xl", "xxl"].includes(screenClass)
													? "-120px"
													: "0",
												marginLeft: ["lg", "xl", "xxl"].includes(screenClass)
													? "40px"
													: "0",
												marginTop: ["lg", "xl", "xxl"].includes(screenClass)
													? "0"
													: "15px",
											}}
										/>
										<div
											className=""
											style={{
												backgroundColor: "white",
												borderRadius: "40px",
												padding: "30px 40px",
												minHeight: "350px",
												height: "100%",
											}}
										>
											<div
												className="review-head"
												style={{ textAlign: "right", paddingBottom: "30px" }}
											>
												<h2
													style={{
														color: "#8810ea",
														fontSize: "30px",
														paddingBottom: "15px",
													}}
												>
													{review.name}
												</h2>
												<h4
													style={{
														color: "#000",
														fontSize: "22px",
														paddingBottom: "15px",
													}}
												>
													<span style={{ fontWeight: "400" }}>
														{review.designation},
													</span>{" "}
													{review.company}
												</h4>
											</div>
											<motion.div
												initial={{ y: "-100%" }}
												whileInView={{ y: 0 }}
												className="review-content"
											>
												<p
													style={{
														textAlign: "center",
														fontSize: "22px",
														lineHeight: "30px",
													}}
												>
													{review.content}
												</p>
											</motion.div>
										</div>
									</div>
								</Container>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
};

export default Review;
