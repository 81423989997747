import React from "react";
import { useState } from "react";
import { Row, Col, Container } from "react-grid-system";
import MerchantHeader from "../MerchantHeader";
import "../style.css";

import AdPoster from "../common/AdPoster";
import { useEffect } from "react";
import CollectionInfo from "../CollectionInfo";
import SingleOrder from "./SingleOrder";
import MultipleOrder from "./MultipleOrder";
import ImportOrder from "./ImportOrder";

const orderTypeData = [
	{ key: 1, title: "single order" },
	{ key: 2, title: "multiple orders" },
	{ key: 3, title: "import from Facebook" },
];

const CreateParcel = () => {
	const [orderType, setOrderType] = useState(1);

	const [ordersArr, setOrdersArr] = useState([]);
	const [collectionInfo, setCollectionInfo] = useState({
		location: "",
		cash: 0,
		deliveryCharge: 60,
		codCharge: 0,
		totalCash: 0,
	});

	//set Collection Info based on Orders array
	//Collection Info: address, cod, total cash
	useEffect(() => {
		let cash = 0;
		ordersArr.map((obj) => {
			let numCash = parseInt(obj.cash);
			cash += numCash;
		});
		let codCharge = cash / 100;
		let totalCash = cash + collectionInfo.deliveryCharge + codCharge;
		setCollectionInfo({
			...collectionInfo,
			cash: cash,
			codCharge: codCharge,
			totalCash: totalCash,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ordersArr]);

	// const [info, setInfo] = useState({
	// 	customer: {
	// 		customer_name: "",
	// 		customer_mobile: "",
	// 		customer_address: "",
	// 		note: "",
	// 	},
	// 	delivery: {
	// 		weight: "",
	// 		phone: "",
	// 		category: "",
	// 		cash: "",
	// 		applicable: {
	// 			liquid: "",
	// 			fragile: "",
	// 			hazardous: "",
	// 			tempControlled: "",
	// 		},
	// 	},
	// });

	// const handleOnchange = (e, key) => {
	// 	setInfo({
	// 		...info,
	// 		[key]: {
	// 			...info[key],
	// 			[e.target.name]: e.target.value,
	// 		},
	// 	});
	// };

	return (
		<div
			style={{
				backgroundColor: "#d9d8d9",
				position: "relative",
				overflow: "hidden",
			}}
		>
			<MerchantHeader />
			<Container style={{ paddingTop: "100px", zIndex: "1" }}>
				<Row>
					<Col>
						<AdPoster
							className="merchant-banner-two"
							link="/use"
							linkTitle="use now"
						/>
					</Col>
				</Row>
				<Row>
					<Col
						lg={8}
						xl={8}
						style={{
							padding: "0 60px",
							backgroundColor: "#fff",
							borderRadius: "10px",
						}}
						className="create-parcel"
					>
						<h4 style={{ fontSize: "38px", fontWeight: "bold" }}>
							Order New Parcel
						</h4>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
								gap: "60px",
								margin: "36px 0",
							}}
						>
							{orderTypeData.map((item) => (
								<div
									key={item.key}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										gap: "30px",
										backgroundColor:
											orderType === item.key ? "#7030a0" : "transparent",
										padding: "10px 20px",
										borderRadius: "10px",
										cursor: "pointer",
										border:
											orderType === item.key ? "none" : "1px solid #7030a0",
										color: orderType === item.key ? "white" : "#7030a0",
										fontWeight: "bold",
										fontSize: "20px",
									}}
									onClick={() => setOrderType(item.key)}
								>
									<div
										className="check-circle"
										style={{
											width: "25px",
											height: "25px",
											borderRadius: "50%",
											backgroundColor:
												orderType === item.key ? "#45ff9c" : "white",
											border:
												orderType === item.key
													? "2px solid #fff"
													: "2px solid #7030a0",
										}}
									></div>{" "}
									<span>{item.title}</span>
								</div>
							))}
						</div>
						<div>
							{orderType === 1 ? (
								<SingleOrder
									ordersArr={ordersArr}
									setOrdersArr={setOrdersArr}
								/>
							) : orderType === 2 ? (
								<MultipleOrder />
							) : (
								<ImportOrder />
							)}
						</div>
					</Col>
					<Col lg={4} xl={4}>
						<CollectionInfo collectionInfo={collectionInfo} />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default CreateParcel;
