import React from "react";
import Layout from "../Layout";
import "../../App.css";
import oldLogo from "../../images/rapido_logo.png";
import rapidoBiker from "../../images/register/My project-1.png";
import { useState } from "react";
import { Col, Container, Hidden, Row, useScreenClass } from "react-grid-system";

import AnimatedBall from "../Landing/AnimatedBall";
import { Link } from "react-router-dom";
import InputElement from "../Utilities/InputElement";
import { LoginAction } from "../../redux/actions/authAction";
import { useDispatch } from "react-redux";
// const dotenv = require("dotenv");
// dotenv.config({ path: ".env" });

const Login = () => {
	const screenClass = useScreenClass();
	const dispatch = useDispatch();
	const token = localStorage.getItem("token");

	const [user, setUser] = useState({
		email: "Aryan",
		password: "",
	});

	// const handleSubmit = async (e) => {
	// 	console.log(baseUrl);
	// 	// e.preventDefault();
	// 	await axios
	// 		.post(`${baseUrl}/auth/login`, user)
	// 		.then((res) => {
	// 			localStorage.setItem("token", res.data.access_token);
	// 		})
	// 		.catch((err) => setError(err.response.data.error));

	// 	window.location.reload();
	// };

	const handleSubmit = async () => {
		dispatch(LoginAction(user));
		// window.location.reload();
	};

	// useEffect(() => {
	// 	token &&
	// 		axios
	// 			.get(`${baseUrl}/auth/me`, {
	// 				headers: {
	// 					Authorization: `Bearer ${token}`,
	// 				},
	// 			})
	// 			.then((res) => {
	// 				localStorage.setItem("profile", JSON.stringify(res.data.profile));
	// 				setLoggedInUser(res.data.profile);
	// 			});
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [token]);
	// console.log(loggedInUser);

	return (
		<Layout>
			<section id="login" className="login" style={{ position: "relative" }}>
				<AnimatedBall top="10%" left="23%" />
				<Hidden sm xs md>
					<img
						src={oldLogo}
						alt="Rapido"
						style={{ position: "absolute", top: "8%", right: "10%" }}
					/>
				</Hidden>
				{!token && (
					<div
						className="round-bg"
						style={{
							height: "350px",
							width: "350px",
							backgroundColor: "#51d379",
							borderRadius: "50%",
							position: "absolute",
							top: "15%",
							left: "35%",
						}}
					></div>
				)}
				<Container
					style={{
						textAlign: "center",
						paddingTop: "160px",
						// zIndex: "111",
					}}
				>
					<div>
						<img
							src={rapidoBiker}
							alt="rapido biker"
							style={{
								width: ["sm", "xs"].includes(screenClass) ? "70%" : "20%",
							}}
						/>
					</div>
					{token ? (
						<div
							className="login-cont"
							style={{
								padding: "20px 0",
								backgroundColor: "white",
								maxWidth: "500px",
								// width: "100%",
								margin: "0 auto",
							}}
						>
							<h2 style={{ fontSize: "50px" }}>Welcome {user.name},</h2>
							<p
								style={{
									fontSize: "28px",
									padding: "25px 0",
									fontWeight: "300",
								}}
							>
								Get ready to experience a hassle free journey with rapido
							</p>
							<button
								style={{
									color: "white",
									backgroundColor: "#7030a0",
									padding: "5px 18px",
									fontSize: "28px",
									border: "none",
									borderRadius: "15px",
								}}
							>
								Login as {user.name}
							</button>
						</div>
					) : (
						<div
							className="login-card"
							style={{
								maxWidth: "550px",
								width: "100%",
								backgroundColor: "#282828",
								borderRadius: "25px",
								padding: "100px 8px 50px 8px",
								margin: "0 auto",
								color: "white",
								marginTop: "-80px",
								boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
							}}
						>
							<Container>
								<Row>
									<Col lg={9} className="tab-col" style={{ margin: "0 auto" }}>
										<InputElement
											label="Email"
											type="text"
											name="email"
											onChange={(e) =>
												setUser({ ...user, email: e.target.value })
											}
											isRequired
										/>
									</Col>
								</Row>
								<Row>
									<Col lg={9} className="tab-col" style={{ margin: "0 auto" }}>
										<InputElement
											label="Password"
											type="password"
											name="password"
											onChange={(e) =>
												setUser({ ...user, password: e.target.value })
											}
											isRequired
										/>
									</Col>
								</Row>
								<Row>
									<Col>
										<input type="checkbox" name="remember" id="remember" />
										<label htmlFor="remember" style={{ display: "inline" }}>
											remember me
										</label>
									</Col>

									<Col>
										<button
											style={{
												backgroundColor: "transparent",
												border: "none",
												color: "#27d85c",
												fontSize: "20px",
											}}
										>
											forgot password
										</button>
									</Col>
								</Row>
								<Row
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										paddingTop: "30px",
									}}
								>
									<button
										type="submit"
										className="tab-form-btn"
										onClick={() => handleSubmit()}
									>
										Submit
									</button>
								</Row>
							</Container>
						</div>
					)}
					<div
						style={{
							maxWidth: "550px",
							width: "100%",
							margin: "0 auto",
							display: "flex",
							alignItems: "center",
							justifyContent: token ? "center" : "flex-end",
							paddingTop: "20px",
						}}
					>
						<Link
							to="/register"
							style={{
								color: "#8211d8",
								fontSize: token ? "25px" : "20px",
								backgroundColor: "transparent",
								border: "none",
								textTransform: token ? "capitalize" : "lowercase",
							}}
						>
							Register Now
						</Link>
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default Login;
