import React from "react";
import { useState } from "react";
import "../../App.css";
import { MdClose } from "react-icons/md";
// Import Swiper Components & styles
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import imgCrossBorder from "../../images/pricing/7 Cross Border (enterprise).png";
import imgDocuments from "../../images/pricing/7 Documents (enterprise).png";
import imgExpress from "../../images/pricing/7 Express (P2P).png";
import imgIsd from "../../images/pricing/7 ISD (e-com).png";
import imgOsd from "../../images/pricing/7 OSD (e-com).png";
import imgSub from "../../images/pricing/7 SUB (e-com).png";
import { useScreenClass } from "react-grid-system";
import AnimatedBall from "./AnimatedBall";

const tabInfo = [
	{
		key: 1,
		title: "enterprise",
		items: [
			{ key: "cross-border", img: imgCrossBorder },
			{ key: "document", img: imgDocuments },
		],
	},
	{
		key: 2,
		title: "e-commerce",
		items: [
			{ key: "sub", img: imgSub },
			{ key: "isd", img: imgIsd },
			{ key: "osd", img: imgOsd },
		],
	},
	{
		key: 3,
		title: "express",
		items: [{ key: "express", img: imgExpress }],
	},
];

const Pricing = () => {
	const [activeTab, setActiveTab] = useState(2);
	const screenClass = useScreenClass();
	const [isModalOpen, setIsModalOpen] = useState(false);
	return (
		<section
			id="pricing"
			className="pricing"
			style={{
				background: "white",
				marginBottom: ["lg", "xl", "xxl"].includes(screenClass)
					? "350px"
					: "600px",
			}}
		>
			<div className="pricing-top">
				<div className="" style={{ textAlign: "center", padding: "60px 0" }}>
					<h2 style={{ fontSize: "50px", color: "white" }}>pricing</h2>
					<AnimatedBall left="23%" />
					<div
						className="pricing-tab"
						style={{
							color: "white",
							maxWidth: "1050px",
							width: "100%",
							margin: "0 auto",
							// paddingBottom: "300px",
						}}
					>
						<div className="tab-head" style={{ padding: "30px 0" }}>
							{tabInfo.map((item) => (
								<button
									onClick={() => setActiveTab(item.key)}
									key={item.key}
									style={{
										fontSize: "30px",
										backgroundColor: "transparent",
										paddingLeft: "15px",
										paddingRight: "15px",
										margin: ["lg", "xl", "xxl"].includes(screenClass)
											? "0 40px"
											: "20px 40px",
									}}
									className={`${activeTab === item.key && "active-tab"} tab`}
								>
									{item.title}
								</button>
							))}
						</div>
						<div className="tab-body" style={{ position: "relative" }}>
							{tabInfo
								.filter((item) => item.key === activeTab)
								.map((item) => (
									<Swiper
										className={`${
											["lg", "xl", "xxl"].includes(screenClass)
												? "slider-wrapper"
												: ""
										}`}
										modules={[Navigation, Pagination]}
										spaceBetween={30}
										slidesPerView={
											["sm", "xs", "md"].includes(screenClass) ? 1 : 3
										}
										navigation={true}
										pagination={{ clickable: true }}
										// className="slider-wrapper"
										style={{
											padding: ["lg", "xl", "xxl"].includes(screenClass)
												? "55px 40px"
												: "15px 8px",
										}}
										key={item.key}
									>
										{item.items.map((slide, index) => (
											<SwiperSlide
												key={index}
												className="pricing-slide"
												style={{
													width: ["lg", "xl", "xxl"].includes(screenClass)
														? "100%"
														: "50%",
													borderRadius: "50px",
													boxShadow: "rgba(0, 0, 0, 0.35) 10px 20px 40px",
												}}
												onClick={() => setIsModalOpen(!isModalOpen)}
											>
												<img
													src={slide.img}
													alt={slide.key}
													style={{
														width: ["lg", "xl", "xxl"].includes(screenClass)
															? "100%"
															: "80%",
													}}
												/>
											</SwiperSlide>
										))}
									</Swiper>
								))}
							{isModalOpen && (
								<div
									style={{
										maxHeight: "73vh",
										height: "100%",
										width: "100%",
										backgroundColor: "rgba(0, 0, 0, 0.4)",
										position: "absolute",
										top: "0",
										left: "50%",
										transform: "translateX(-50%)",
										zIndex: "99",
										padding: "15px",
										borderRadius: "25px",
									}}
								>
									<div
										className="modal"
										style={{
											position: "absolute",
											top: "15px",
											right: "15px",
										}}
									>
										<button
											className="close-btn"
											onClick={() => setIsModalOpen(!isModalOpen)}
											style={{
												padding: "12px",
												fontSize: "45px",
												border: "none",
												borderRadius: "50%",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												cursor: "pointer",
											}}
										>
											<MdClose className="icon" />
										</button>
									</div>
									{activeTab === 1 ? (
										<div>tab : {activeTab}</div>
									) : activeTab === 2 ? (
										<div>tab : {activeTab}</div>
									) : (
										<div>tab : {activeTab}</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Pricing;
