import React from "react";
import "../../App.css";
import { motion } from "framer-motion";

// Import Swiper Components & styles
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

//import images
import imgFounder from "../../images/story/7 founder 1.png";
import imgICT from "../../images/story/7 BASIS ICT 2.jpg";
import imgAward from "../../images/story/7 BASIS ICT 2.png";
import { Container, Hidden, useScreenClass } from "react-grid-system";

const Story = () => {
	const screenClass = useScreenClass();
	return (
		<section
			className="story-full"
			style={{
				width: "100%",
				// height: ["lg", "xl", "xxl"].includes(screenClass) && "90vh",
				position: "relative",
				paddingTop: "40px",
			}}
		>
			<h2
				style={{
					position: ["lg", "xl", "xxl"].includes(screenClass)
						? "absolute"
						: "static",
					right: "5%",
					top: "35%",
					color: "#45ff9c",
					fontSize: ["lg", "xl", "xxl"].includes(screenClass) ? "70px" : "50px",
					textTransform: "uppercase",
					textAlign: ["md", "xs", "sm"].includes(screenClass) && "center",
					padding: ["md", "xs", "sm"].includes(screenClass) && "30px 0",
				}}
			>
				Our Story
			</h2>
			<Swiper
				modules={[Navigation, Pagination]}
				spaceBetween={0}
				slidesPerView={1}
				navigation={true}
				loop={true}
			>
				<SwiperSlide>
					<div
						style={{
							textAlign: ["xs", "lg", "md"].includes(screenClass) && "center",
							paddingTop: "60px",
						}}
					>
						<Container>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: ["lg", "xl", "xxl"].includes(screenClass)
										? "flex-start"
										: "center",
								}}
							>
								<motion.img
									initial={{ opacity: 0 }}
									whileInView={{ opacity: 1 }}
									transition={{ delay: 1 }}
									src={imgFounder}
									alt="Founder"
									className="ict-group-img"
									style={{
										width: ["lg", "xl", "xxl"].includes(screenClass)
											? "20%"
											: "90%",
										// marginBottom: ["lg", "xl", "xxl"].includes(screenClass)
										// 	? "0"
										// 	: "100px",
										borderRadius: "15px",
										position: "relative",
									}}
								/>
							</div>
						</Container>
						<div
							className=""
							style={{
								backgroundColor: "#490775",
								paddingTop: "55px",
								// minHeight: "250px",
								minHeight: "264px",
								height: "100%",
							}}
						>
							<Container>
								<motion.p
									initial={{ opacity: 0 }}
									whileInView={{ opacity: 1 }}
									transition={{ delay: 1.25 }}
									style={{
										color: "white",
										fontSize: "24px",
										padding: ["lg", "xl", "xxl"].includes(screenClass)
											? "15px 140px"
											: "15px 10px",
										textAlign: "center",
										lineHeight: "30px",
										// marginTop: "50px",
									}}
								>
									Just like any startups, Rapido was founded in a garage on 2015
									by Shamdanee Tabriz & his co-founder Tamzid Ferdous. The
									journey began with only 2 bi-cycles headed by the founders
									iniitially.
									<br />
									<br />
									The company later went on the receive numerous accolades both
									nationally & internationally.
								</motion.p>
							</Container>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div
						style={{
							textAlign: ["xs", "lg", "md"].includes(screenClass) && "center",
						}}
					>
						<Container
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: "80px",
							}}
						>
							<motion.img
								initial={{ opacity: 0 }}
								whileInView={{ opacity: 1 }}
								transition={{ delay: 1 }}
								src={imgICT}
								alt="Founder"
								className="ict-group-img"
								style={{
									width: ["lg", "xl", "xxl"].includes(screenClass)
										? "40%"
										: "90%",
									marginBottom: ["lg", "xl", "xxl"].includes(screenClass)
										? "30px"
										: "100px",
									borderRadius: "15px",
									position: "relative",
								}}
							/>
							<Hidden sm xs md>
								<img
									src={imgAward}
									alt="Founder"
									style={{ width: "16%", transform: "rotate(20deg)" }}
								/>
							</Hidden>
						</Container>
						<div
							className=""
							style={{
								backgroundColor: "#490775",
								paddingTop: "40px",
								minHeight: "264px",
								height: "100%",
							}}
						>
							<Container>
								<motion.p
									initial={{ opacity: 0 }}
									whileInView={{ opacity: 1 }}
									transition={{ delay: 1.25 }}
									style={{
										color: "white",
										fontSize: "24px",
										padding: ["lg", "xl", "xxl"].includes(screenClass)
											? "15px 140px"
											: "15px 10px",
										textAlign: "center",
										lineHeight: "30px",
										// marginTop: "50px",
									}}
								>
									Rapido Ltd. won the BASIS National ICT Awards on 2017. The
									event was graced by the presence of distinguished guests,
									notably the Honorable State Minister Mr. Junayed Ahmed Palak,
									ICT Minister Mr. Mostoffa Jabbar & the former Country head of
									Microsoft Mrs. Sonia Bashir.
								</motion.p>
							</Container>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</section>
	);
};

export default Story;
