import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Container, useScreenClass } from "react-grid-system";
import "../../App.css";

import akijLogo from "../../images/our-clients/Akij-Essentials-Limited.png";
import atpoure from "../../images/our-clients/Atpoure.png";
import bele from "../../images/our-clients/BELE-ROBE-BOUTIQUE-Recovered.png";
import casual from "../../images/our-clients/Casual-Park.png";
import clovia from "../../images/our-clients/Cloovia.png";
import daraz from "../../images/our-clients/daraz-logo.png";
import dnStore from "../../images/our-clients/DN-STORE-LTD.png";
import dusell from "../../images/our-clients/Duesell.png";
import easternBank from "../../images/our-clients/Eastern-Bank-LTD.png";
import expertApp from "../../images/our-clients/Expert-Appliance-Bd.png";
import ghuddi from "../../images/our-clients/Ghuddi.png";
import goldSands from "../../images/our-clients/Goldsands-Hotels-_-Resorts-LTD.png";
import jothashilpa from "../../images/our-clients/JOTHASHILPA.png";
import koreanMart from "../../images/our-clients/koreAN-MART-logo-Recovered.png";
import livingText from "../../images/our-clients/Livingtex.png";
import monowaMart from "../../images/our-clients/Monowa-Mart.png";
import newTant from "../../images/our-clients/New-Tant-ghar.png";
import nooriva from "../../images/our-clients/Nooriva.png";
// import nurTaj from "../../images/our-clients/Nurtaj-Bangladesh-Ltd.png";
// import ruhiMart from "../../images/our-clients/Ruhi-E-Mart.png";
// import shahajalal from "../../images/our-clients/shahjalal.png";
// import shonoDip from "../../images/our-clients/Shopnodip.png";
// import strides from "../../images/our-clients/Strides-Co-Ltd.png";
// import tameez from "../../images/our-clients/Tameez-Bangladesh.png";
// import bookshelf from "../../images/our-clients/The-bookshelf.png";
// import zCo from "../../images/our-clients/Z_Co.png";
// import zikra from "../../images/our-clients/Zikra-Enterprise.png";
// import nilkhet from "../../images/our-clients/নীলক্ষেত.com.png";

//others img import
// import clientsBg from "../../images/white cloudy.png";

const clientsIcons = [
	{ name: "nilkhet", img: akijLogo },
	{ name: "nilkhet", img: atpoure },
	{ name: "nilkhet", img: bele },
	{ name: "nilkhet", img: casual },
	{ name: "nilkhet", img: clovia },
	{ name: "nilkhet", img: daraz },
	{ name: "nilkhet", img: dnStore },
	{ name: "nilkhet", img: dusell },
	{ name: "nilkhet", img: easternBank },
	{ name: "nilkhet", img: expertApp },
	{ name: "nilkhet", img: ghuddi },
	{ name: "nilkhet", img: goldSands },
	{ name: "nilkhet", img: jothashilpa },
	{ name: "nilkhet", img: koreanMart },
	{ name: "nilkhet", img: livingText },
	{ name: "nilkhet", img: monowaMart },
	{ name: "nilkhet", img: newTant },
	{ name: "nilkhet", img: nooriva },
	// { name: "nilkhet", img: nurTaj },
	// { name: "nilkhet", img: ruhiMart },
	// { name: "nilkhet", img: shahajalal },
	// { name: "nilkhet", img: shonoDip },
	// { name: "nilkhet", img: strides },
	// { name: "nilkhet", img: tameez },
	// { name: "nilkhet", img: bookshelf },
	// { name: "nilkhet", img: zCo },
	// { name: "nilkhet", img: zikra },
	// { name: "nilkhet", img: nilkhet },
];

const OurClients = () => {
	const screenClass = useScreenClass();
	// const prevRef = useRef();
	// const nextRef = useRef();

	return (
		<section
			id="our-clients"
			className="our-clients"
			style={{
				padding: "80px 0",
				// 	backgroundImage: `url(${clientsBg})`,
				// 	backgroundPosition: "top left",
				// 	backgroundRepeat: "no-repeat",
				// 	backgroundSize: "cover",
			}}
		>
			<h2
				style={{
					textAlign: "center",
					fontSize: "60px",
					fontWeight: "500",
					paddingBottom: "50px",
				}}
			>
				We work with
			</h2>

			<Swiper
				modules={[Navigation, Pagination]}
				spaceBetween={0}
				slidesPerView={1}
				navigation={true}
				loop={true}
			>
				<SwiperSlide>
					<Container
						style={{
							display: "grid",
							gridTemplateColumns: ["xl", "xxl", "md"].includes(screenClass)
								? "repeat(6, 1fr)"
								: "repeat(2, 1fr)",
							gridTemplateRows: ["xl", "xxl", "md"].includes(screenClass)
								? "repeat(3, 1fr)"
								: "repeat(9, 1fr)",
							alignItems: "center",
							justifyContent: "center",
							gap: "20px 15px",
						}}
					>
						{clientsIcons.map((item, index) => (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								key={index}
							>
								<img src={item.img} alt="logo" key={index} width="50%" />
							</div>
						))}
					</Container>
				</SwiperSlide>
			</Swiper>
		</section>
	);
};

export default OurClients;
