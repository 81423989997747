import React from "react";
import Layout from "../../Layout";

const AdminDashboard = () => {
	return (
		<Layout>
			<div>
				<h2>Admin Dashboard</h2>
			</div>
		</Layout>
	);
};

export default AdminDashboard;
