import React from "react";
import { Container } from "react-grid-system";
import Layout from "../Layout";
import "./style.css";

const PrivacyPolicy = () => {
	return (
		<Layout>
			<Container style={{ paddingTop: "110px", paddingBottom: "30px" }}>
				<p>
					This privacy policy describes how the rapido.com.bd website and
					related applications (the “Site”, “we” or “us”) collect, use, share
					and protect the personal information that we collect through this
					Site. Rapido Ltd. has established this Site to link up users who need
					something shipped or delivered (“Customers”) with individuals who will
					provide the shipping and delivery services (“Couriers”) or teams of
					affiliated Couriers (“Teams”). This policy also applies to any mobile
					applications that we develop for use with our services and Team
					specific pages on the Site, and references to this “Site”, “we” or
					“us” is intended to also include these mobile applications. Please
					read below to learn more about our information practices. By using
					this Site, you agree to these practices.
				</p>
				<h2>How We Collect Information</h2>
				<h4>Information Provided By Your Web Browser</h4>
				<p>
					You have to provide us with personal information like your name,
					contact no, mailing address, and email id, our app will also fetch
					your location information to give you the best service. Like many
					other websites, we may record information that your web browser
					routinely shares, such as your browser type, browser language,
					software and hardware attributes, the date and time of your visit, the
					web page from which you came, your Internet Protocol address and the
					geographic location associated with that address, the pages on this
					Site that you visit and the time you spent on those pages. This will
					generally be anonymous data that we collect on an aggregate basis. We
					may also use Google Analytics or a similar service to gather
					statistical information about the visitors to this Site and how they
					use the Site. This, also, is done on an anonymous basis. We will not
					try to associate anonymous data with your personally identifiable
					data. If you would like to learn more about Google Analytics, please
					click here.
				</p>
				<h4>Personal Information That You Provide</h4>
				<p>
					If you want to use our service or contact a Rapido member, you must
					create an account on our Site. To establish your account, we will ask
					for personally identifiable information that can be used to contact or
					identify you, which may include your name, phone number, and e-mail
					address. We may also collect demographic information about you, such
					as your zip code, and allow you to submit additional information that
					will be part of your Rapido profile. <br />
					Other than the basic information that we need to establish your
					account, it will be up to you to decide how much information to share
					as part of your profile. We encourage you to think carefully about the
					information that you share and we recommend that you guard your
					identity and your sensitive information. Of course, you can review and
					revise your profile at any time. <br />
					From time to time, we may run contests or promotions and ask for a
					postal mailing address and other personal information relating to the
					contest or promotion. It will always be your choice whether to provide
					your personal information to participate in these events.
				</p>
				<h4>Payment Information</h4>
				<p>
					Our payment channel includes cash on delivery and payment through MFS
					(eg: BKash, No god, etc). Thus, if you are a Customer, you will make
					payments once the courier is at your doorstep by cash or using your
					MFS App. We will have your address and contact no. to identify the
					customer.
				</p>
				<h4>Information About Others</h4>
				<p>
					If you are a Customer planning to ship something using the Rapido
					service, we will ask you for information about the sender location and
					contact and recipient location, and contact of the shipment.
				</p>
				<h2>Use Of Cookies And Similar Technology</h2>
				<h2>Session And Persistent Cookies</h2>
				<p>
					As is commonly done on websites, we may use cookies and similar
					technology to keep track of our users and the services they have
					elected. A “cookie” is a small text file containing alphanumeric
					characters that are stored on your computer’s hard drive and uniquely
					identify your browser. We use both “session” and “persistent” cookies.
					Session cookies are deleted after you leave our website and when you
					close your browser. We use data collected with session cookies to
					enable certain features on our Site, to help us understand how users
					interact with our Site, and to monitor at an aggregate level Site
					usage and web traffic routing. <br />
					If you have created an account, we will also use persistent cookies
					that remain on your computer’s hard drive between visits, so that when
					you return to our Site we can remember who you are and your
					preferences. For example, after you log out of our Site, these
					persistent cookies can enable you to return to our Site without the
					need to log back in. <br />
					We may allow business partners who provide services to our Site to
					place cookies on your computer that assist us in analyzing usage data.
					We do not allow these business partners to collect your personal
					information from our website except as may be necessary for the
					services that they provide. <br />
					You can manage these cookies. For example, you can configure your
					browser to accept all cookies, reject all cookies, or notify you when
					a cookie is set. If you disable cookies, however, it may interfere
					with the functionality of our Site and you may not be able to use all
					of the Site’s features.
				</p>
				<h4>Web Beacons</h4>
				<p>
					We may also use web beacons or similar technology to help us track the
					effectiveness of our communications. For example, if you have elected
					to receive any of our email newsletters, we may use technology that
					allows us to see how many recipients have opened the message and how
					many have clicked on one of its links.
				</p>
				<h4>Advertising Cookies</h4>
				<p>
					We may use third parties, such as Google, to serve ads about our
					website over the internet. These third parties may use cookies to
					identify ads that may be relevant to your interest (for example, based
					on your recent visit to our website), to limit the number of times
					that you see an ad, and to measure the effectiveness of the ads. You
					can disable these advertising cookies by opting out at
					<a href="http://www.google.com/privacy_ads.html">
						http://www.google.com/privacy_ads.html
					</a>
					.
				</p>
				<h4>What We Do With The Information We Collect</h4>
				<p>
					We will generally use the information that we collect to provide our
					services, monitor and analyze visitor activity on our website, promote
					and support our services, and develop a knowledge base regarding our
					website users. As detailed below, certain information that you provide
					may be available to visitors to the Site, and some information will be
					shared between Customers, Teams, and/or Couriers.
				</p>
				<h4>Registered Rapido Users</h4>
				<p>
					When you register on our Site or App, you will create a user name and
					profile. Your user name and profile will be accessible by the users of
					our Site. With your permission, we may also share information about
					your use of the service on third-party sites. For example, we may
					allow you to elect to share information about the services we provide
					through this site as updates to your Facebook or Twitter accounts.{" "}
					<br />
					If you post a job as a Customer, we may publish the address of the
					pickup and drop off locations on the Site, viewable by all Rapido
					Teams or Couriers. For example, if you choose to post a job to the
					Site for a specific Team, we will publish the address of the pickup
					and drop off locations on the Site or App, viewable to that specific
					Team.
				</p>
				<h4>Your Contact Information</h4>
				<p>
					When you provide us with your contact information, we will use that
					information to communicate with you about your use of our service. For
					example, when you have entered into a confirmed transaction with us,
					we will use your contact information to notify you of the transaction.
					We will also share your contact information with the couriers so that
					you may contact each other about the transaction. If you agree, we may
					also use your e-mail address to send you a newsletter or other
					information about our services or about other products or services in
					which you may be interested. You may change your preferences at any
					time, though you will not be able to opt out of messages relating to
					your use of our service.
				</p>
				<h4>Testimonials</h4>
				<p>
					We may allow you to submit testimonials about your experience with our
					Site. If you provide a testimonial, we may post it on this website
					along with your name. If you want your testimonial removed, please
					contact us at <a href="info@rapido.com.bd.">info@rapido.com.bd.</a>
				</p>
				<h4>Ratings And Reviews</h4>
				<p>
					If you are a Customer, you will be able to rate and review a Courier.
					If you choose to submit a rating, this will be aggregated with other
					ratings and available to other registered users of the Site. If you
					submit a review, your review along with your username will be posted
					for everyone to see.
				</p>
				<h4>Anonymous Data</h4>
				<p>
					We use the anonymous data that we collect on an aggregate basis to
					gain a better understanding of the visitors to our Site and to improve
					our website and product offerings. We reserve the right to license or
					sell this aggregated information to third parties for industry
					analysis, demographic profiling, and other purposes, but this
					information will not contain your individually identifiable personal
					information.
				</p>
				<h4>Shipment Recipients And Referrals</h4>
				<p>
					We may provide an e-mail/ text messaging service for alerting
					recipients that a shipment is on its way through the Rapido service.
					If you elect to provide us with an e-mail address or contact no. of
					the person or organization receiving your shipment, we will send the
					recipient a notification message of the shipment and the expected
					delivery time frame. We may also use the recipient’s contact
					information from time to time to send the recipient a promotional
					message about our services, and will always give the recipient the
					option to decline any future promotional messages. <br />
					We may also provide you with the opportunity to refer a potential
					customer to our Rapido services and earn a commission on the referral.
					To take advantage of this opportunity, you will need to provide Rapido
					with the e-mail address of the potential customer. We will include
					your name in the promotional message that we send the potential
					customer, but will always give the potential customer the option to
					decline any future promotional messages. <br />
					Of course, we will not share recipient or potential customer contact
					information with other third parties for their marketing purposes. We
					may, however, share the recipient or potential customer contact
					information with Teams or Super Teams to perform the delivery
					services.
				</p>
				<h4>Information Shared With Our Business Partners</h4>
				<p>
					We may use business partners to help us design and operate our Site
					and provide services to support the Site. We may also hire a company
					to run certain website applications, provide data storage and
					processing services, or help us analyze our data. These business
					partners may have access to the personal information that we keep, but
					only so that they may perform these tasks on our behalf. We do not
					allow these business partners to make any independent commercial use
					of the individually identifiable information that we store, to share
					such data with third parties, or from making the data publicly
					available. However, keep in mind that if you establish a different
					relationship with one of these business partners, the information you
					provide directly to that organization will be subject to its terms of
					use and privacy practices. <br />
					We may also provide your personal information to our business partners
					or other trusted entities to provide you with information on goods or
					services we believe will be of interest to you. You can, at any time,
					opt-out of receiving such communications. Third-party vendors,
					including Google, use cookies to serve ads based on a user’s prior
					visits to Rapido. You can disable these specific cookies that track
					prior visits for the sake of follow-up advertising by opting out at{" "}
					<a href="http://www.google.com/privacy_ads.html">
						http://www.google.com/privacy_ads.html
					</a>
					.
				</p>
				<h4>Information Shared With Super Teams</h4>
				<p>
					Teams for different geographic areas may be organized under a larger
					entity (a “Super Team”). Any information that you share with the Site
					may also be shared with a Super Team and may be used by the Super Team
					in any manner consistent with this Privacy Policy as if the Super Team
					were us.
				</p>
				<h4>Business Operations, Law Enforcement, And Legal Actions</h4>
				<p>
					We may release your information without prior notice when we believe
					it is appropriate to prevent fraud or to prevent or stop the activity
					that we know or suspect may be illegal, unethical, or legally
					actionable; to comply with the law or to cooperate with law
					enforcement activity or another governmental request; to respond to
					subpoenas, court orders or administrative agency requests for
					information; to enforce our policies; to protect the rights, property,
					and safety of our business and others; or when otherwise required by
					law. If there is a sale or merger of the company, division, or
					business unit that operates this Site, we may also transfer the
					information we have collected in connection with such sale or merger.
					We will use the information we collect to continuously improve our
					business and our website development. Your comments and suggestions
					are always appreciated. Please contact us at{" "}
					<a href="info@rapido.com.bd ">info@rapido.com.bd </a> if you have any
					comments or suggestions.
				</p>
				<h2>Third-Party Websites</h2>
				<p>
					This Site may contain links to other websites operated by companies
					that are not affiliated with us. Also, you may have come to this
					website from a website that is not operated by us. We are not
					responsible for the operation of these other sites or the information
					that they collect from their visitors. If you would like to know how
					another site collects and uses your information, please review its
					privacy policy.
				</p>
				<h4>Changes To Your Information</h4>
				<p>
					The information you provide us isn’t set in stone. You may review,
					update, correct or delete the personal information in your profile at
					any time. If you would like us to remove your information from our
					records, please contact us at info@rapido.com.bd. We will attempt to
					accommodate your request if we do not have a legal obligation to
					retain the record.
				</p>
				<h2>Device information</h2>
				<p>
					When you use your mobile device to access/avail of our service or
					visit our site for interaction and information, we may receive
					information about your mobile device, including the hardware models,
					device IP address, SDK versions, operating systems and versions,
					software, preferred language, and country site, internet browser,
					unique device identifiers, advertising identifiers, serial numbers and
					mobile network information. We may also inject markers in the driver
					app through storage access in order to prevent polymorphism. <br />
					We and our service providers and third parties we collaborate with,
					including ad networks, may use cross-device/cross-context tracking.
					For example, you might use multiple browsers on a single device, or
					use various devices (such as desktops, smartphones, and tablets),
					which can result in you having multiple accounts or profiles across
					these various contexts and devices. Cross-device/cross-context
					technology may be used to connect these various accounts or profiles
					and the corresponding data from the different contexts and devices.
					Device information i.e. the devices you use (mobile phones, computers,
					tablets, etc.) to access our services such as the hardware models,
					operation system information, software information and version, file
					names, language preferences, IP address cookie information,
					advertising identifiers, browser version, device settings, and mobile
					network information. We may recognize your devices to provide you with
					personalized experiences and advertising across the services you use.
				</p>
				<h2>How We Protect Your Data</h2>
				<p>
					If you have registered on this Site, you should be sure to protect
					your user ID and password to prevent others from being able to access
					the Site in your name. You should also be on guard for “phishing”
					scams and similar types of identity theft schemes. We do not and will
					not, at any time, request your credit card information, your account
					ID, login password, or national identification numbers in a non-secure
					or unsolicited e-mail or telephone communication. <br />
					If there is a breach of security involving your personal data that
					requires notification, you agree that we may notify you about the
					breach via email or by a conspicuous posting on this Site. We will
					make the notification without unreasonable delay, consistent with the
					legitimate needs of law enforcement and any measures necessary to
					determine the scope of the breach and restore the reasonable integrity
					of the data system. <br />
					If you have any questions about security on our website, you can
					contact us at <a href="info@rapido.com.bd">info@rapido.com.bd</a>.
				</p>
				<h4>Data Integrity And Retention</h4>
				<p>
					We use the information that we collect about you only for the purposes
					for which it is collected and consistent with this policy. We keep
					information provided to us for as long as we believe necessary for our
					business purposes and as permitted by applicable law.
				</p>
				<h4>Changes To This Policy</h4>
				<p>
					Our business and the laws that regulate us change from time to time,
					and we reserve the right to change this policy. If we do change this
					policy, we will post the revised version on this Site. If we propose
					to change our policy in a way that would permit us to make additional
					uses of information that we had previously collected about you, we
					will provide you with a meaningful way to opt out of those additional
					uses.
				</p>
				<h2>Data Subject to Bangladesh Law</h2>
				<p>
					Rapido is located and operates its website in Dhaka, Bangladesh.
					Depending on where you live, the information that you provide and that
					this Site collects may be stored on servers that are outside of your
					state, province, country, or other governmental jurisdiction, and the
					privacy laws that apply may not be as protective as those in your home
					jurisdiction. If you are located outside Bangladesh and choose to
					provide information to us, Bangladesh transfers personal information
					to other countries and processes it there. By using this website, you
					consent to this transfer and processing of data.
				</p>
				<h3>Children</h3>
				<p>
					While our Site is available for all to come visit, you must be an
					adult to register on our website and use our services. We will not
					knowingly collect information about children under the age of 18. If
					you are a parent who believes that we have collected information about
					a child under the age of 18, please contact us at info@rapido.com.bd
					with your child’s name and address, and we will be happy to delete the
					information we have about your child from our records in accordance
					with childrens act 2013.
				</p>
				<h3>This Policy Is An Agreement</h3>
				<p>
					When you visit this Site, you are accepting the practices described in
					this Privacy Policy.
				</p>
			</Container>
		</Layout>
	);
};

export default PrivacyPolicy;
