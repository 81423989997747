import React, { useState } from "react";
import { Col, Container, Row, useScreenClass } from "react-grid-system";
import Layout from "../Layout";
import "../../App.css";
import mascot from "../../images/register/111.png";
import mascotGif from "../../images/register/1.gif";
import InputElement from "../Utilities/InputElement";

const formTabInfo = [
	{ id: 1, title: "Company Info" },
	{ id: 2, title: "Owner Info" },
	{ id: 3, title: "Payment Info" },
];

const EcomRegister = () => {
	const [registerInfo, setRegisterInfo] = useState({
		companyInfo: {
			company: "",
			companyUrl: "",
			city: "",
			email: "",
			mobile: "",
			productCategory: "",
			dbidNumber: "",
		},
		ownerInfo: {
			firstName: "",
			lastName: "",
			gender: "",
			phone: "",
			address: "",
		},
		paymentInfo: {
			paymentMethod: "",
			details: "",
		},
		user: {
			username: "",
			password: "",
		},
	});

	// const [onSubmit, setOnSubmit] = useState(false);
	const [activeTab, setActiveTab] = useState(1);
	const [isError, setIsError] = useState(false);

	const screenClass = useScreenClass();

	const handleOnchange = (e, key) => {
		setRegisterInfo({
			...registerInfo,
			[key]: {
				...registerInfo[key],
				[e.target.name]: e.target.value,
			},
		});
	};

	console.log(registerInfo);

	return (
		<Layout>
			<section id="ecom-register" style={{ padding: "80px 0" }}>
				<Container>
					<div
						className="register-card"
						style={{
							maxWidth: "650px",
							width: "100%",
							backgroundColor: "#3a3a3a",
							borderRadius: "25px",
							padding: "45px 8px",
							margin: "0 auto",
							color: "white",
						}}
					>
						{activeTab < 4 && (
							<div className="head" style={{ textAlign: "center" }}>
								<h2 style={{ color: "white" }}>Merchant registration form</h2>
								<p>E-commerce delivery</p>
							</div>
						)}
						<div className="form-tab" style={{ width: "100%" }}>
							{activeTab < 4 && (
								<div
									className="tab-head"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										flexDirection:
											["xs", "sm"].includes(screenClass) && "column",
										gap: ["xs", "sm"].includes(screenClass) && "10px",
										margin: "30px 0",
									}}
								>
									{formTabInfo.map((item) => (
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: "column",
											}}
											key={item.id}
										>
											<div
												style={{
													// padding: "25px",
													width: "50px",
													height: "50px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													borderRadius: "50%",
													fontSize: "22px",
													backgroundColor:
														activeTab === item.id ? "#45ff9c" : "white",
													color: "black",
													cursor: "pointer",
												}}
												onClick={() => setActiveTab(item.id)}
											>
												{item.id}
											</div>
											<h4 style={{ color: "white" }}>{item.title}</h4>
										</div>
									))}
								</div>
							)}
							<div className="tab-body" style={{ width: "100%" }}>
								{activeTab === 1 ? (
									<Container>
										<Row>
											<Col lg={6} className="tab-col">
												{/*<label htmlFor="company">Company</label>
											<input type="text" name="company" id="company" />*/}
												<InputElement
													label="Company"
													type="text"
													name="company"
													onChange={(e) => handleOnchange(e, "companyInfo")}
												/>
											</Col>
											<Col lg={6} className="tab-col">
												<InputElement
													label="website/fb url"
													type="text"
													name="companyUrl"
													onChange={(e) => handleOnchange(e, "companyInfo")}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={6} className="tab-col">
												<InputElement
													label="City"
													type="text"
													name="city"
													onChange={(e) => handleOnchange(e, "companyInfo")}
												/>
											</Col>
											<Col lg={6} className="tab-col">
												<InputElement
													label="Email"
													type="email"
													name="email"
													onChange={(e) => handleOnchange(e, "companyInfo")}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={6} className="tab-col">
												<InputElement
													label="Mobile"
													type="text"
													name="mobile"
													onChange={(e) => handleOnchange(e, "companyInfo")}
												/>
											</Col>
											<Col lg={6} className="tab-col">
												<InputElement
													label="Product Category"
													type="text"
													name="productCategory"
													onChange={(e) => handleOnchange(e, "companyInfo")}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={12} className="tab-col">
												<InputElement
													label="DBID Number"
													type="text"
													name="dbidNumber"
													onChange={(e) => handleOnchange(e, "companyInfo")}
												/>
											</Col>
										</Row>
									</Container>
								) : activeTab === 2 ? (
									<Container>
										<Row>
											<Col lg={6} className="tab-col">
												<InputElement
													label="First name"
													type="text"
													name="firstName"
													onChange={(e) => handleOnchange(e, "ownerInfo")}
												/>
											</Col>
											<Col lg={6} className="tab-col">
												<InputElement
													label="Last name"
													type="text"
													name="lastName"
													onChange={(e) => handleOnchange(e, "ownerInfo")}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={6} className="tab-col">
												<label htmlFor="gender">gender</label>
												<select
													name="gender"
													id="gender"
													onChange={(e) => handleOnchange(e, "ownerInfo")}
													style={{ color: "black" }}
												>
													<option value="" selected>
														Select an option
													</option>
													<option value="male">Male</option>
													<option value="female">Female</option>
												</select>
											</Col>
											<Col lg={6} className="tab-col">
												<InputElement
													label="Phone"
													type="text"
													name="phone"
													onChange={(e) => handleOnchange(e, "ownerInfo")}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={12} className="tab-col">
												<InputElement
													label="Address"
													type="text"
													name="address"
													onChange={(e) => handleOnchange(e, "ownerInfo")}
												/>
											</Col>
										</Row>
									</Container>
								) : activeTab === 3 ? (
									<Container>
										<Row>
											<Col
												lg={9}
												className="tab-col"
												style={{ margin: "0 auto" }}
											>
												<label htmlFor="payment" style={{ margin: "0 auto" }}>
													Preferred payment channel
												</label>
												<select
													name="paymentMethod"
													id="paymentMethod"
													onChange={(e) => handleOnchange(e, "paymentInfo")}
													style={{ color: "black" }}
												>
													<option value="" selected>
														Select an option
													</option>
													<option value="bank">Bank</option>
													<option value="bkash">Bkash</option>
													<option value="office-collection">
														Office Collection
													</option>
												</select>
											</Col>
										</Row>
										<Row>
											<Col lg={12} className="tab-col">
												<label htmlFor="details">Details</label>
												<textarea
													name="details"
													id="details"
													rows="6"
													onChange={(e) => handleOnchange(e, "paymentInfo")}
													style={{ color: "black" }}
												/>
											</Col>
										</Row>
									</Container>
								) : activeTab === 4 ? (
									<Container>
										<Row>
											<Col lg={12} style={{ textAlign: "center" }}>
												<h4 style={{ fontSize: "34px" }}>almost there</h4>
												<p style={{ padding: "25px 0" }}>
													Please choose a username & password for your account
												</p>
											</Col>
										</Row>
										<Row>
											<Col
												lg={9}
												className="tab-col"
												style={{ margin: "0 auto" }}
											>
												<InputElement
													label="Username"
													type="text"
													name="username"
													onChange={(e) => handleOnchange(e, "user")}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												lg={9}
												className="tab-col"
												style={{ margin: "0 auto" }}
											>
												<InputElement
													label="Password"
													type="password"
													name="password"
													onChange={(e) => handleOnchange(e, "user")}
												/>
											</Col>
										</Row>
									</Container>
								) : (
									<Container>
										<Row>
											<Col
												lg={12}
												style={{
													position: "relative",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													flexDirection: "column",
												}}
											>
												<img
													src={mascot}
													alt="Mascot"
													style={{ width: "40%", height: "80%" }}
												/>
												<img
													src={mascotGif}
													alt="Animation gif"
													style={{
														position: "absolute",
														top: "-100px",
														left: "10%",
														width: "80%",
														height: "90%",
													}}
												/>
												<h2 style={{ padding: "15px 0", fontSize: "45px" }}>
													Welcome
												</h2>
												<p>You’re ready to GROW with Rapido</p>
												<small style={{ paddingTop: "50px" }}>
													Please check your email for the confirmation message
												</small>
											</Col>
										</Row>
									</Container>
								)}
							</div>
						</div>
						{activeTab < 5 && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									paddingTop: "10px",
								}}
							>
								<button
									className="tab-form-btn"
									onClick={() => {
										!isError
											? activeTab < 4
												? setActiveTab(activeTab + 1)
												: setActiveTab(5)
											: console.log(isError);
									}}
								>
									{activeTab === 4 ? "Submit" : "Next"}
								</button>
							</div>
						)}
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default EcomRegister;
