import React from "react";
import { Col, Container, Hidden, Row, useScreenClass } from "react-grid-system";
import { motion } from "framer-motion";
import MerchantHeader from "./MerchantHeader";
import createParcel from "../../../images/dashboard/create parcel.png";
import mascot from "../../../images/banner/mascot.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./style.css";
import AdPoster from "./common/AdPoster";

const MerchantDashboard = () => {
	const screenClass = useScreenClass();
	const user = JSON.parse(localStorage.getItem("user"));
	const [searchDate, setSearchDate] = useState({
		start: "",
		end: "",
	});

	return (
		<div
			style={{
				backgroundColor: "#d9d8d9",
				position: "relative",
				overflow: "hidden",
			}}
		>
			<MerchantHeader />
			<Container style={{ paddingTop: "100px", zIndex: "1" }}>
				<Row style={{ padding: "30px 0" }}>
					<Col>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
							}}
						>
							<h2 style={{ fontSize: "40px" }}>hello {user?.name} </h2>
							<div
								style={{
									marginTop: "20px",
									height: "15px",
									width: "15px",
									borderRadius: "50%",
									backgroundColor: "#0fdb4c",
									marginLeft: "8px",
								}}
							></div>
						</div>
						<p style={{ color: "#8211d8" }}>
							Welcome to your business account.
						</p>
					</Col>
					<Col>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							<Link
								to="/merchant/create-parcel"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
								}}
							>
								<img src={createParcel} alt="create parcel" />
							</Link>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<AdPoster
							className="merchant-banner-one"
							link="/apply"
							linkTitle="apply now"
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<AdPoster
							className="merchant-banner-two"
							link="/use-now"
							linkTitle="use now"
						/>
					</Col>
				</Row>
				<Row style={{ padding: "40px 0" }}>
					<Col sm={12} lg={5} style={{ margin: "0 auto" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: ["xs", "sm", "md"].includes(screenClass)
									? "column"
									: "row",
							}}
						>
							<div
								style={{
									color: "gray",
									backgroundColor: "white",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									padding: "5px 0",
									borderRadius: "10px",
									fontSize: "20px",
									boxShadow: "2px 5px 25px #b2b2b2",
									width: "100%",
								}}
							>
								<input
									type="text"
									onFocus={(e) => (e.target.type = "date")}
									onBlur={(e) => (e.target.type = "text")}
									name="start"
									id="start"
									placeholder="start date"
									onChange={(e) =>
										setSearchDate({ ...searchDate, start: e.target.value })
									}
									style={{
										border: "none",
										textAlign: "center",
										fontSize: "18px",
										width: "100%",
										color: "gray",
									}}
								/>{" "}
								-{" "}
								<input
									type="text"
									onFocus={(e) => (e.target.type = "date")}
									onBlur={(e) => (e.target.type = "text")}
									name="end"
									id="end"
									placeholder="end date"
									onChange={(e) =>
										setSearchDate({ ...searchDate, end: e.target.value })
									}
									style={{
										border: "none",
										textAlign: "center",
										fontSize: "18px",
										width: "100%",
										color: "gray",
									}}
								/>
							</div>
							<input
								type="submit"
								value="search"
								style={{
									color: "white",
									backgroundColor: "#7030a0",
									padding: "8px 25px",
									fontSize: "22px",
									borderRadius: "10px",
									boxShadow: "2px 5px 15px #b2b2b2",
									border: "none",
									cursor: "pointer",
								}}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<Hidden sm md xs>
				<motion.div
					initial={{ scale: 0 }}
					animate={{ rotate: 180, scale: 1 }}
					transition={{
						type: "spring",
						stiffness: 260,
						damping: 20,
					}}
					className="mascot-round"
					style={{
						width: "350px",
						height: "350px",
						backgroundColor: "#51d379",
						borderRadius: "50%",
						position: "fixed",
						bottom: "200px",
						right: "-4%",
					}}
				></motion.div>
				<motion.img
					initial={{ right: "-150%" }}
					animate={{ right: "0%" }}
					transition={{ type: "spring", stiffness: 260, damping: 40 }}
					src={mascot}
					alt="mascot"
					style={{
						width: "10%",
						position: "fixed",
						right: "-0%",
						bottom: "150px",
					}}
				/>
			</Hidden>
		</div>
	);
};

export default MerchantDashboard;
