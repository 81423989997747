import React from "react";
import { motion } from "framer-motion";

//import images
import packagingImg from "../../images/services/packaging.png";
import pickupImg from "../../images/services/e-com-delivery.png";
import supportImg from "../../images/services/love.png";
import shipppingImg from "../../images/services/cross-border.png";
import serviceBg from "../../images/services/services-bg.png";
import { useScreenClass } from "react-grid-system";
import AnimatedBall from "./AnimatedBall";

const serviceItems = [
	{
		img: packagingImg,
		subTitle: "e-commerce",
		title: "Packaging",
	},
	{
		img: pickupImg,
		subTitle: "Doorstep",
		title: "Pickup",
	},
	{
		img: supportImg,
		subTitle: "personalized",
		title: "Support",
	},
	{
		img: shipppingImg,
		subTitle: "Cross border",
		title: "Shipping",
	},
];

//service Item Sub component
const ServiceItem = ({ item }) => {
	const screenClass = useScreenClass();
	return (
		<div style={{ textAlign: "center" }}>
			<img
				src={item.img}
				alt={item.title}
				style={{
					height: ["lg", "xl", "xxl"].includes(screenClass) ? "250px" : "200px",
					paddingBottom: ["lg", "xl", "xxl"].includes(screenClass)
						? "10px"
						: "0",
				}}
			/>
			<h4 style={{ fontWeight: "300", fontSize: "27px", color: "gray" }}>
				{item.subTitle}
			</h4>
			<h2
				style={{
					color: "#8211d8",
					fontSize: "30px",
					fontWeight: "bold",
				}}
			>
				{item.title}
			</h2>
		</div>
	);
};

//Main Component
const Services = () => {
	const screenClass = useScreenClass();
	return (
		<section id="services" style={{ width: "100%" }}>
			<div
				style={{
					padding: "80px 0",
					color: "white",
					textAlign: "center",
					backgroundImage: `url(${serviceBg})`,
					backgroundPosition: "top left",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					position: "relative",
				}}
			>
				<div>
					<AnimatedBall top="30%" left="23%" />
					<h2
						style={{
							fontSize: "65px",
							fontWeight: "500",
							textTransform: "capitalize",
							color: "white",
						}}
					>
						we're rapido
					</h2>
					<h4
						style={{
							fontSize: "30px",
							fontWeight: "normal",
							paddingTop: "25px",
							color: "white",
						}}
					>
						We provide a blend of delivery support
					</h4>
				</div>
			</div>
			<div className="service-bottom">
				<div
					className="service-wrapper"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "80px",
						flexDirection: ["xl", "xxl"].includes(screenClass)
							? "row"
							: "column",
						gap: "25px",
					}}
				>
					{serviceItems.map((item, index) => (
						<motion.div
							key={index}
							initial={{ opacity: 0 }}
							whileInView={{ opacity: 1 }}
							transition={{ duration: index * 0.85 }}
						>
							<ServiceItem item={item} />
						</motion.div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Services;
