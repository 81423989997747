import React from "react";
import { Container } from "react-grid-system";
import Layout from "./Layout";

const UserProfile = () => {
	const { name, user_type } = JSON.parse(localStorage.getItem("user"));

	return (
		<Layout>
			<Container style={{ paddingTop: "110px" }}>
				<h2>User Profile</h2>
				<h1>name: {name}</h1>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
				<h3>User Type : {user_type}</h3>
			</Container>
		</Layout>
	);
};

export default UserProfile;
