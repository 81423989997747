import React from "react";
import "../../App.css";

//import icons
import { FaApple, FaGooglePlay } from "react-icons/fa";

//import Images
import iPhoneGif from "../../images/App Rapido 2.gif";
import rapidoAlien from "../../images/8 service.png";
import { Hidden, useScreenClass } from "react-grid-system";
import AnimatedBall from "./AnimatedBall";

const AppStore = () => {
	const screenClass = useScreenClass();
	return (
		<section
			id="app-full"
			style={{
				position: "relative",
				width: "100%",
				height: ["lg", "xl", "xxl"].includes(screenClass) && "90vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<AnimatedBall top="10%" />
			<div
				className="bg-black"
				style={{
					width: ["xs", "sm", "md"].includes(screenClass) ? "100%" : "20%",
					height: "100%",
					backgroundColor: "black",
				}}
			>
				<div
					className="app-content"
					style={{
						color: "white",
						position: ["xs", "sm", "md"].includes(screenClass)
							? "static"
							: "absolute",
						bottom: "4%",
						left: "4%",
						textAlign: ["xs", "sm", "md"].includes(screenClass) && "center",
						paddingTop: ["xs", "sm", "md"].includes(screenClass) && "30px",
					}}
				>
					<h2
						style={{ fontSize: "64px", fontWeight: "normal", color: "white" }}
					>
						Happy <span style={{ color: "#8211d8" }}>with</span>
						<br />
						what you <span style={{ color: "#8211d8" }}>see?</span>
					</h2>
					<h4
						style={{
							paddingTop: "100px",
							fontSize: "35px",
							fontWeight: "400",
							color: "white",
						}}
					>
						Download <br />
						the <span style={{ color: "#45ff9c" }}>Rapido</span> app <br />
						Today!
					</h4>
					<div className="app-btn">
						<button
							style={{
								cursor: "pointer",
							}}
						>
							<FaApple /> <span>App store</span>
						</button>
						<button style={{ cursor: "pointer" }}>
							<FaGooglePlay /> <span>Play store</span>
						</button>
					</div>
				</div>
			</div>
			<Hidden sm xs md>
				<div
					className="bg-white"
					style={{
						width: "80%",
						height: "100%",
						backgroundColor: "white",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						// gap: "80px",
					}}
				>
					<img
						src={iPhoneGif}
						alt="I phone"
						style={{ width: "30%", display: "block" }}
					/>
					<img
						src={rapidoAlien}
						alt="Rapido Alien"
						style={{
							width: "20%",
							position: "absolute",
							right: "1%",
							bottom: "3%",
						}}
					/>
				</div>
			</Hidden>
		</section>
	);
};

export default AppStore;
