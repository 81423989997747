import React from "react";
import { Hidden, useScreenClass } from "react-grid-system";
import "../../App.css";

const Delivery = () => {
	const screenClass = useScreenClass();
	return (
		<section
			id="delivery"
			className="delivery"
			style={{
				height: "90vh",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<div
				className="delivery-left"
				style={{
					width: ["lg", "xl", "xxl"].includes(screenClass) ? "40%" : "100%",
					height: "100%",
					paddingLeft: ["lg", "xl", "xxl"].includes(screenClass)
						? "80px"
						: "10px",
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					gap: "50px",
				}}
			>
				<h2 style={{ fontSize: "60px", color: "white" }}>
					<span style={{ color: "#4fffa1" }}>Save</span>
					<br /> your time,
				</h2>
				<h2 style={{ fontSize: "60px", color: "white" }}>
					<span style={{ color: "#4fffa1" }}>Grow</span>
					<br /> your business
				</h2>
			</div>
			<Hidden sm xs md>
				<div
					className="delivery-right"
					style={{ width: "60%", height: "100%" }}
				></div>
			</Hidden>
		</section>
	);
};

export default Delivery;
