import React from "react";
import { motion } from "framer-motion";

//import images
import imgHeavy from "../../images/register/delivery.png";
import imgECom from "../../images/register/e-com.png";
import imgDoc from "../../images/register/document.png";
import imgInt from "../../images/register/int.png";
import { Link } from "react-router-dom";
import { useScreenClass } from "react-grid-system";
import Layout from "../Layout";

const registerItems = {
	heavy: {
		slug: "heavy",
		img: imgHeavy,
		subTitle: "heavy items/bulk",
		title: "delivery",
	},
	eCom: {
		slug: "/register/e-com",
		img: imgECom,
		subTitle: "e-commerce",
		title: "delivery",
	},
	doc: {
		slug: "document",
		img: imgDoc,
		subTitle: "document",
		title: "delivery",
	},
	int: {
		slug: "international",
		img: imgInt,
		subTitle: "International",
		title: "delivery",
	},
};

const Registration = () => {
	const screenClass = useScreenClass();
	return (
		<Layout>
			<section id="register" className="register">
				<div
					className="register-head"
					style={{
						maxWidth: "450px",
						width: "100%",
						margin: "0 auto",
						textAlign: "center",
						// backgroundColor: "white",
						padding: "100px 0",
					}}
				>
					<h2
						style={{
							fontSize: "50px",
							backgroundColor: "white",
							lineHeight: "100px",
						}}
					>
						registration
					</h2>
					<p
						style={{
							fontSize: "25px",
							backgroundColor: "white",
							lineHeight: "40px",
						}}
					>
						select your desired service from the list
					</p>
				</div>
				<div
					className="register-wrapper"
					style={{
						padding: ["xxl", "xl", "lg"].includes(screenClass)
							? "0 150px"
							: "0 15px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: ["xxl", "xl", "lg"].includes(screenClass)
							? "row"
							: "column",
					}}
				>
					{Object.values(registerItems).map((item, index) => (
						<motion.div
							initial={{ opacity: 0 }}
							whileInView={{ opacity: 1 }}
							transition={{ duration: index * 0.85 }}
							key={index}
							style={{
								width: ["xxl", "xl", "lg"].includes(screenClass)
									? "20%"
									: "100%",
								textAlign: ["sm", "xs", "md"].includes(screenClass) && "center",
								margin: ["sm", "xs", "md"].includes(screenClass) && "15px 0",
							}}
						>
							<Link to={item.slug}>
								<img
									src={item.img}
									alt=""
									style={{
										width: "250px",
										height: "250px",
									}}
								/>
								<div
									style={{
										textAlign: "center",
										backgroundColor: "#bababa",
										padding: "30px 0",
										borderRadius: "18px",
										border: "4px solid #fff",
										boxShadow:
											"rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
									}}
								>
									<p style={{ color: "#212121", fontSize: "25px" }}>
										{item.subTitle}
									</p>
									<h4 style={{ color: "#8211d8", fontSize: "30px" }}>
										{item.title}
									</h4>
								</div>
							</Link>
						</motion.div>
					))}
				</div>
			</section>
		</Layout>
	);
};

export default Registration;
