import React from "react";
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select } from "antd";
import {
	PlusCircleOutlined,
	// CheckCircleOutlined,
	// WarningOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const SingleOrderForm = ({
	ordersArr,
	setOrdersArr,
	isShow = false,
	item,
	index,
}) => {
	const [form] = Form.useForm();

	//submit form data: Form submission
	const onFinish = (values) => {
		if (isShow) {
			let newArr = [...ordersArr];
			newArr[index] = values;
			console.log(newArr);
			setOrdersArr(newArr);
		} else {
			setOrdersArr([...ordersArr, values]);
			form.resetFields();
		}
	};

	//failed to submit SingleOrderForm data
	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Form
			form={form}
			name="Parcels"
			layout="vertical"
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			initialValues={item}
		>
			<h2>{index >= 0 ? `Order no: ${index + 1}` : `New Order Form`}</h2>
			<div className="customer-info">
				<h4 style={{ fontSize: "25px", fontWeight: "500" }}>
					Customer information
				</h4>
				<div
					style={{
						display: "flex",
						width: "100%",
						gap: "50px",
						padding: "15px 0",
					}}
				>
					<Form.Item
						style={{ width: "50%" }}
						label="Customer name"
						name="customer_name"
						rules={[
							{
								required: true,
								message: "Please input customer name!",
							},
						]}
					>
						<Input placeholder="Customer name" />
					</Form.Item>
					<Form.Item
						style={{ width: "50%" }}
						label="Customer Phone number"
						name="customer_mobile"
						rules={[
							{
								required: true,
								message: "Please input customer phone number!",
							},
						]}
					>
						<Input placeholder="Customer Phone number" />
					</Form.Item>
				</div>
				<div
					style={{
						display: "flex",
						width: "100%",
						gap: "50px",
						padding: "15px 0",
					}}
				>
					<Form.Item
						style={{ width: "50%" }}
						label="Customer address"
						name="customer_address"
						rules={[
							{
								required: true,
								message: "Please input Customer Address!",
							},
						]}
					>
						<Input placeholder="Customer Address" />
					</Form.Item>

					<Form.Item style={{ width: "50%" }} label="Note" name="note">
						<Input placeholder="Order Note" />
					</Form.Item>
				</div>
			</div>
			<div>
				<h4 style={{ fontSize: "25px", fontWeight: "500" }}>
					delivery information
				</h4>
				<div
					style={{
						display: "flex",
						width: "100%",
						gap: "50px",
						padding: "15px 0",
					}}
				>
					<Form.Item
						style={{ width: "50%" }}
						label="weight"
						name="weight"
						rules={[
							{
								required: true,
								message: "Please input product weight!",
							},
						]}
					>
						<Input placeholder="Weight (KG)" />
					</Form.Item>
					<Form.Item
						style={{ width: "50%" }}
						label="phone number"
						name="phone"
						rules={[
							{
								required: true,
								message: "Please input phone number!",
							},
						]}
					>
						<Input placeholder="phone number" />
					</Form.Item>
				</div>
				<div
					style={{
						display: "flex",
						width: "100%",
						gap: "50px",
						padding: "15px 0",
					}}
					className="category-select"
				>
					<Form.Item
						name="category"
						label="category"
						style={{
							width: "50%",
						}}
						rules={[
							{
								required: true,
								message: "Please select category!",
							},
						]}
					>
						<Select placeholder="select your category" size="large">
							<Option value="cat 1">Cat 1</Option>
							<Option value="cat 2">Cat 2</Option>
							<Option value="cat 3">Cat 3</Option>
						</Select>
					</Form.Item>
					<Form.Item
						style={{ width: "50%" }}
						label="Cash Collection"
						name="cash"
						rules={[
							{
								required: true,
								message: "Please input cash!",
							},
						]}
					>
						<Input placeholder="cash" />
					</Form.Item>
				</div>
				<div
					style={{
						display: "flex",
						width: "100%",
						gap: "50px",
						padding: "15px 0",
					}}
				>
					<Form.Item name="applicable" label="applicable">
						<Checkbox.Group>
							<Row>
								{["fragile", "liquid", "hazardous", "tempControlled"].map(
									(item) => (
										<Col
											key={item}
											style={{
												width: "",
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-start",
												padding: "8px 10px",
												border: "1px solid #5b0a82",
												borderRadius: "10px",
												gap: "20px",
												fontSize: "18px",
												margin: "0 30px",
											}}
										>
											<Checkbox
												value={item}
												style={{
													lineHeight: "32px",
												}}
											>
												{item}
											</Checkbox>
										</Col>
									)
								)}
							</Row>
						</Checkbox.Group>
					</Form.Item>
				</div>
			</div>
			<div>
				<Divider orientation="right">
					<Button
						type="primary"
						shape="round"
						icon={<PlusCircleOutlined />}
						size="large"
						htmlType="submit"
					>
						{isShow ? "Update" : "Add Orders"}
					</Button>
				</Divider>
			</div>
		</Form>
	);
};

export default SingleOrderForm;
