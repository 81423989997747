import React from "react";
import taka2 from "../../../images/dashboard/rsz_taka_2.png";
const CollectionInfo = ({ collectionInfo }) => {
	return (
		<div
			style={{
				backgroundColor: "white",
				padding: "10px",
				borderRadius: "8px",
			}}
		>
			<div
				style={{
					padding: "0 20px",
					marginBottom: "30px",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						paddingBottom: "20px",
					}}
				>
					<h2>prity's collection</h2>{" "}
					<button
						style={{
							border: "none",
							color: "#5d06a0",
							fontWeight: "bold",
							backgroundColor: "transparent",
							fontSize: "20px",
							cursor: "pointer",
						}}
					>
						change
					</button>
				</div>
				<div>
					<p style={{ lineHeight: "40px" }}>
						<b>pickup location: </b> <br /> H 8/A/10, Lalmatia, Mohammadpur
					</p>
				</div>
			</div>
			<div style={{ backgroundColor: "#7030a0", padding: "15px" }}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						paddingBottom: "50px",
					}}
				>
					<h2 style={{ color: "white" }}>delivery charge</h2>
					<img
						src={taka2}
						alt="taka"
						style={{ marginTop: "-80px", marginRight: "-20px" }}
					/>
				</div>
				<div style={{ padding: "10px" }}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							margin: "20px 0",
							fontSize: "20px",
							color: "#fff",
						}}
					>
						<span>cash collection</span>
						<span>Tk. {collectionInfo.cash}</span>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							margin: "20px 0",
							fontSize: "20px",
							color: "#fff",
						}}
					>
						<span>delivery charge</span>
						<span>Tk. {collectionInfo.deliveryCharge}</span>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							margin: "20px 0",
							fontSize: "20px",
							color: "#fff",
						}}
					>
						<span>COD charge</span>
						<span>Tk. {collectionInfo.codCharge}</span>
					</div>
					<div
						style={{
							width: "100%",
							height: "2px",
							backgroundColor: "#45ff9c",
							margin: "30px 0",
						}}
					></div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							margin: "25px 0",
							fontSize: "20px",
							color: "#fff",
							fontWeight: "bold",
						}}
					>
						<span>total amount</span>
						<span>Tk. {collectionInfo.totalCash}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CollectionInfo;
