import React, { Fragment } from "react";

const InputElement = ({ label, type, name, onChange, isRequired }) => {
	return (
		<Fragment>
			<label htmlFor={name}>{label}</label>
			<input
				type={type}
				name={name}
				id={name}
				onChange={onChange}
				style={{ color: "black" }}
			/>
		</Fragment>
	);
};

export default InputElement;
