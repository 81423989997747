import { CloudUploadOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Table, Upload } from "antd";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
const { Dragger } = Upload;

const columns = [
	{
		title: "ID",
		dataIndex: "id",
		key: "id",
	},
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Phone",
		dataIndex: "phone",
		key: "phone",
	},
];

const MultipleOrder = () => {
	// const [uploadStatus, setUploadStatus] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [tableHeader, setTableHeader] = useState([]);
	const [csvArray, setCsvArray] = useState([]);
	console.log(tableHeader);

	const [isTableShow, setIsTableShow] = useState(false);

	const csvFileToArray = (string) => {
		// console.log(string);
		const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
		const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
		const array = csvRows.map((i) => {
			const values = i.split(",");
			const obj = csvHeader.reduce((object, header, index) => {
				// let tableObj = {
				// 	title: header,
				// 	dataIndex: header,
				// 	key: header,
				// };

				// setTableHeader([...tableHeader, tableObj]);
				object[header] = values[index];
				return object;
			}, {});

			return obj;
		});
		// setTableHeader(csvHeader);
		// console.log(array);
		setCsvArray(array);
	};

	const props = {
		name: "file",
		multiple: false,
		// action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
		id: "csvFileInput",
		accept: ".txt, .csv",
		showUploadList: false,

		//onchange input: File Upload handler
		onChange(info) {
			const { status } = info.file;
			if (status !== "uploading") {
				// console.log(info.file, info.fileList);
			}
			if (status === "done") {
				// message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === "error") {
				// message.error(`${info.file.name} file upload failed.`);
			}
		},
		beforeUpload: (file) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				const text = e.target.result;
				csvFileToArray(text);
			};
			reader.readAsText(file);

			// Prevent upload
			return false;
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	// File Submission
	const handleSubmit = () => {
		console.log("submitted");
	};
	return (
		<div>
			<h2>Upload order file</h2>
			<div>
				<Dragger
					{...props}
					style={{
						backgroundColor: `${
							csvArray.length > 0 ? "lightgreen" : "transparent"
						}`,
					}}
				>
					<p className="ant-upload-drag-icon">
						{csvArray.length > 0 ? (
							<CheckOutlined
							// style={{ fontSize: "60px", color: "red !important" }}
							/>
						) : (
							<CloudUploadOutlined />
						)}
					</p>
					{csvArray.length > 0 ? (
						<p>File Uploaded</p>
					) : (
						<p>
							Drag a <b>.csv</b> file or click to select
						</p>
					)}
				</Dragger>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginTop: "20px",
					}}
				>
					<Button
						size="large"
						type="primary"
						disabled={csvArray.length > 0 ? false : true}
						onClick={() => setCsvArray([])}
					>
						Cancel
					</Button>
					{isTableShow ? (
						<Link
							to="/merchant/import-status"
							type="primary"
							onClick={handleSubmit}
						>
							Place Orders
						</Link>
					) : (
						<Button
							size="large"
							type="primary"
							disabled={csvArray.length > 0 ? false : true}
							onClick={() => setIsTableShow(true)}
						>
							Next
						</Button>
					)}
				</div>
				{csvArray.length > 0 ? (
					<Table dataSource={csvArray} columns={columns} />
				) : null}
			</div>
		</div>
	);
};

export default MultipleOrder;
