import axios from "axios";
import { AUTH } from "../../App.config";
import {
	setIsAuthenticated,
	setIsValidating,
	setToken,
	setUser,
} from "../reducers/authReducer";

//Login Action
export const LoginAction = (user) => {
	return (dispatch) => {
		// Set `isValidating`
		dispatch(setIsValidating(true));

		axios
			.post(AUTH.LOGIN_API, user)
			.then((res) => {
				if (res.data && res.data.access_token) {
					const token = res.data.access_token;

					// Validate User
					dispatch(ValidateUserAction(token));
				} else {
					throw new Error("Token Not Found");
				}
			})
			.catch((err) => {
				console.error(err);

				// Dispatch `authReducer` Values to Redux State
				dispatch(setIsAuthenticated(false));
				dispatch(setToken(null));
				dispatch(setUser({}));

				// Set `isValidating`
				dispatch(setIsValidating(false));
			});
	};
};

//Logout Action
export const LogoutAction = () => {
	return (dispatch) => {
		//set Validating
		dispatch(setIsValidating(true));
		//clear localStorage
		localStorage.clear();
		//dispatch authReducer values initState
		dispatch(setIsAuthenticated(false));
		//set validating false
		dispatch(setIsValidating(false));

		window.location = "/login";
	};
};

//validate User
export const ValidateUserAction = (token) => {
	return (dispatch) => {
		//set Validating
		dispatch(setIsValidating(true));
		//get method with get user api
		axios
			.get(AUTH.GET_USER_API, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				if (res.data.profile) {
					const user = res.data.profile;
					//localStorage set token
					localStorage.setItem("token", token);
					//localStorage set user_info
					localStorage.setItem("user", JSON.stringify(user));
					//dispatch auth, token, user
					dispatch(setIsAuthenticated(true));
					dispatch(setToken(token));
					dispatch(setUser(user));

					// Set axios default token
					axios.defaults.headers.common.Authorization = `Bearer ${token}`;

					dispatch(setIsValidating(false));
				} else {
					throw new Error("User Not Found");
				}
			})
			.catch((err) => {
				console.error(err);

				// Dispatch authReducer Values to Redux State
				dispatch(setIsAuthenticated(false));
				dispatch(setToken(null));
				dispatch(setUser({}));

				// Set `isValidating`
				dispatch(setIsValidating(false));
			});
	};
};
