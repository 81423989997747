import React from "react";
import { Hidden, useScreenClass } from "react-grid-system";
import { motion } from "framer-motion";

import mascotManImg from "../../images/coverage/mascot-coverage.png";
import { Link } from "react-router-dom";

const Coverage = () => {
	const screenClass = useScreenClass();
	return (
		<section
			id="coverage"
			className="coverage-full"
			style={{
				width: "100%",
				height: ["xl", "xxl", "lg"].includes(screenClass) && "95vh",
				position: "relative",
				padding: "50px 0",
			}}
		>
			<div
			// style={{
			// 	backgroundColor:
			// 		["xs", "sm", "md"].includes(screenClass) && "rgba(91, 91, 91, 0.5)",
			// }}
			>
				<div
					// className="coverage-inner"
					style={{
						width: ["lg", "xl", "xxl"].includes(screenClass) ? "50%" : "100%",
						textAlign: ["lg", "xl", "xxl"].includes(screenClass)
							? "right"
							: "center",
						position: ["lg", "xl", "xxl"].includes(screenClass)
							? "absolute"
							: "static",
						right: "3%",
						bottom: "29%",
						padding: ["xs", "sm", "md"].includes(screenClass) ? "100px 0" : "0",
					}}
				>
					<h2
						style={{
							fontSize: "50px",
							textTransform: "uppercase",
							color: "#3f3f3f",
						}}
					>
						Coverage
					</h2>
					<h4
						style={{
							fontSize: "30px",
							color: "#282828",
							fontWeight: "400",
							padding: "20px 0",
						}}
					>
						deliver all over Bangladesh with COD
					</h4>
					<div style={{ paddingTop: "-50px" }}>
						<h4
							style={{
								color: "white",
								fontSize: "30px",
								fontWeight: "400",
							}}
						>
							check out our coverage areas
						</h4>
						<Link
							to="/"
							style={{
								color: "white",
								backgroundColor: "#09000f",
								padding: "8px 15px",
								border: "4px solid #6f1da5",
								borderRadius: "10px",
								fontSize: "30px",
								fontWeight: "500",
							}}
						>
							click here
						</Link>
					</div>
				</div>
				<Hidden sm xs md>
					<motion.div
						initial={{ x: "-50" }}
						whileInView={{ x: 0 }}
						transition={{ stiffness: 260, damping: 20 }}
						className="mascot-man"
						style={{
							position: "absolute",
							left: "0%",
							bottom: "20%",
						}}
					>
						<img
							src={mascotManImg}
							alt="Mascot Man"
							style={{ width: "100%" }}
						/>
					</motion.div>
				</Hidden>
			</div>
		</section>
	);
};

export default Coverage;
