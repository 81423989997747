import React, { useState } from "react";

import rapidoLogo from "../../../images/dashboard/rsz_logo_png_original_2.png";
import { Link, useLocation } from "react-router-dom";
import "../../../App.css";
import { motion } from "framer-motion";

import { MdMenu, MdKeyboardArrowDown } from "react-icons/md";
import {
	Col,
	Container,
	Hidden,
	Row,
	useScreenClass,
	Visible,
} from "react-grid-system";
import { useDispatch } from "react-redux";
import { LogoutAction } from "../../../redux/actions/authAction";
import { Divider } from "antd";

const leftMenuItems = [
	{
		title: "dashboard",
		id: "dashboard",
	},
	{
		title: "parcels",
		id: "parcels",
	},
	{
		title: "payments",
		id: "payments",
	},
	{
		title: "coupon",
		id: "coupon",
	},
	{
		title: "help",
		id: "help",
	},
];

const MenuItem = ({
	item,
	activeNav,
	setActiveNav,
	visible,
	setVisible,
	pathname,
}) => {
	//Merchant Menu Link click handler
	const handleClick = (item) => {
		setActiveNav(item.id);
		setVisible(!visible);
	};
	return (
		<a
			href={item.id}
			style={{
				fontSize: "20px",
				padding: "8px 5px 3px 5px",
				margin: "0 5px",
				color: "black",
				fontWeight: "bold",
				borderBottom:
					pathname?.split("/")[2] === item.id
						? "8px solid #45ff9d"
						: "8px solid transparent",
			}}
			onClick={() => handleClick(item)}
		>
			{item.title}
		</a>
	);
};

const MerchantHeader = () => {
	const [visible, setVisible] = useState(false);
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	const screenClass = useScreenClass();
	const [activeNav, setActiveNav] = useState("dashboard");
	const { pathname } = useLocation();
	const user = JSON.parse(localStorage.getItem("user"));
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(LogoutAction());
	};

	return (
		<motion.nav
			initial={{ y: "-50%" }}
			animate={{ y: "0" }}
			transition={{ duration: 1 }}
			className="navbar"
			style={{ backgroundColor: "white", padding: "10px 0" }}
		>
			<Container className="nav">
				<Row
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Col>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
								gap: "30px",
							}}
						>
							<Link to="/">
								<img
									src={rapidoLogo}
									alt="rapido"
									className="logo-img"
									style={{
										width: ["lg", "xl", "xxl"].includes(screenClass)
											? "200px"
											: "180px",
									}}
								/>
							</Link>
							<Link
								to="/track"
								style={{
									color: "white",
									backgroundColor: "#7030a0",
									padding: "3px 20px",
									fontSize: "22px",
									borderRadius: "10px",
								}}
							>
								track
							</Link>
						</div>
					</Col>
					<Hidden xs sm md>
						<Col>
							<div
								className="menu"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<div
									className="left-menu"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										gap: "25px",
									}}
								>
									{leftMenuItems.map((item, index) => (
										<MenuItem
											item={item}
											key={index}
											activeNav={activeNav}
											setActiveNav={setActiveNav}
											pathname={pathname}
										/>
									))}
								</div>
								<div
									className="right-menu"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderLeft: "1px solid gray",
										paddingLeft: "35px",
									}}
								>
									<div
										style={{
											color: "#45ff9c",
											fontSize: "25px",
											backgroundColor: "#5b0a82",
											fontWeight: "bold",
											padding: "10px 20px",
											borderRadius: "50%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<div
											style={{ position: "relative", cursor: "pointer" }}
											onClick={() => setIsProfileDropdown(!isProfileDropdown)}
										>
											<div>
												<span>{user?.name.slice(0, 1)}</span>
											</div>
											{isProfileDropdown && (
												<div
													style={{
														position: "absolute",
														top: "35px",
														left: "-10px",
														width: "220px",
														backgroundColor: "#fff",
														padding: "15px 8px",
														borderRadius: "12px",
														boxShadow: "rgba(0, 0, 0, 0.55) 0px 5px 15px",
													}}
												>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
														}}
													>
														<div
															style={{
																color: "#45ff9c",
																fontSize: "25px",
																backgroundColor: "#5b0a82",
																fontWeight: "bold",
																padding: "10px 20px",
																borderRadius: "50%",
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
															}}
														>
															<span>{user?.name.slice(0, 1)}</span>
														</div>
														<div
															style={{ fontWeight: "bold", color: "#54088e" }}
														>
															{user?.name}
														</div>
													</div>
													<Divider style={{ margin: "10px 0" }} />
													<div
														style={{
															display: "flex",
															alignItems: "flex-end",
															justifyContent: "center",
															flexDirection: "column",
														}}
													>
														<Link
															to="/merchant/user-profile"
															style={{
																color: "#583772",
																fontSize: "20px",
																margin: "10px  0",
															}}
														>
															my Profile
														</Link>
														<Link
															to="/merchant/business-settings"
															style={{
																color: "#583772",
																fontSize: "20px",
																margin: "10px  0",
															}}
														>
															business settings
														</Link>
														<Link
															to="/merchant/pickup-location"
															style={{
																color: "#583772",
																fontSize: "20px",
																margin: "10px  0",
															}}
														>
															pickup location
														</Link>
														<div
															style={{
																cursor: "pointer",
																paddingTop: "10px",
																color: "#5b0a82",
																fontSize: "22px",
															}}
															onClick={() => handleLogout()}
														>
															Logout
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
									<MdKeyboardArrowDown />
								</div>
							</div>
						</Col>
					</Hidden>

					<Visible xs sm md>
						<Col>
							<div
								onClick={() => {
									setVisible(!visible);
								}}
								style={{
									fontSize: "35px",
									textAlign: "right",
									color: "#7030a0",
								}}
							>
								<MdMenu />
							</div>
						</Col>
					</Visible>
				</Row>
				{visible && (
					<Row>
						<Col>
							<div
								className="menu"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									flexDirection: "column",
									position: "absolute",
									top: "0",
									left: "0",
									width: "100%",
									backgroundColor: "white",
								}}
							>
								<div
									className="left-menu"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										flexDirection: "column",
									}}
								>
									{leftMenuItems.map((item, index) => (
										<MenuItem
											item={item}
											key={index}
											visible={visible}
											setVisible={setVisible}
										/>
									))}
								</div>
								<div
									className="right-menu"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										gap: "40px",
										paddingBottom: "15px",
									}}
								>
									<div
										style={{
											color: "#45ff9c",
											fontSize: "20px",
											backgroundColor: "#5b0a82",
											fontWeight: "bold",
											padding: "4px 8px",
											borderRadius: "10px",
										}}
									>
										{user ? (
											<div style={{ position: "relative" }}>
												<div
													style={{ cursor: "pointer" }}
													onClick={() =>
														setIsProfileDropdown(!isProfileDropdown)
													}
												>
													<span>{user?.name}</span>
												</div>
												{isProfileDropdown && (
													<div
														style={{
															position: "absolute",
															top: "35px",
															left: "-10px",
															width: "220px",
															backgroundColor: "#fff",
															padding: "15px 8px",
															borderRadius: "12px",
															boxShadow: "rgba(0, 0, 0, 0.55) 0px 5px 15px",
														}}
													>
														<Link
															to="/merchant/user-profile"
															style={{
																color: "#583772",
																fontSize: "20px",
																margin: "10px  0",
															}}
														>
															my Profile
														</Link>
														<Link
															to="/merchant/business-settings"
															style={{
																color: "#583772",
																fontSize: "20px",
																margin: "10px  0",
															}}
														>
															business settings
														</Link>
														<Link
															to="/merchant/pickup-location"
															style={{
																color: "#583772",
																fontSize: "20px",
																margin: "10px  0",
															}}
														>
															pickup location
														</Link>
														<div
															style={{
																cursor: "pointer",
																color: "#5b0a82",
																fontSize: "22px",
															}}
															onClick={() => handleLogout()}
														>
															Logout
														</div>
													</div>
												)}
											</div>
										) : (
											<Link to="/login" style={{ color: "#45ff9c" }}>
												Login
											</Link>
										)}
									</div>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</Container>
		</motion.nav>
	);
};

export default MerchantHeader;
