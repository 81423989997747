import React from "react";
import { Container } from "react-grid-system";
import { Button, Dropdown, Menu, Table } from "antd";
import DashboardHeader from "./MerchantHeader";
import { useState } from "react";
import {
	AiOutlineDownload,
	AiOutlinePrinter,
	AiOutlineYoutube,
	AiOutlineCopy,
} from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";

const tableFilterData = [
	{ key: 1, title: "placed orders", value: "placed" },
	{ key: 2, title: "running orders", value: "running" },
	{ key: 3, title: "returned orders", value: "returned" },
	{ key: 4, title: "completed orders", value: "completed" },
];

const rows = [
	{
		orderDate: "Sept 17, 2022",
		customerName: "samdani",
		parcelId: "434DFSDF34",
		invoiceId: "PARCEL#5354343",
		customerDetails: "samdani",
		phone: "01711993344",
		address: "Lata Laksmipur Sadar, Laksmipur Sadar",
		status: "placed",
		note: "",
		paymentInfo: "456 tk",
		deliveryType: "Cash on",
		lastUpdated: "",
		action: "...",
	},
	{
		orderDate: "Sept 17, 2022",
		customerName: "rapido",
		parcelId: "434DFSDF34",
		invoiceId: "PARCEL#5354343",
		customerDetails: "samdani",
		phone: "01711993344",
		address: "Lata Laksmipur Sadar, Laksmipur Sadar",
		status: "running",
		note: "",
		paymentInfo: "456 tk",
		deliveryType: "Cash on",
		lastUpdated: "",
		action: "...",
	},
	{
		orderDate: "Sept 17, 2022",
		customerName: "samdani",
		parcelId: "434DFSDF34",
		invoiceId: "PARCEL#5354343",
		customerDetails: "samdani",
		phone: "01711993344",
		address: "Lata Laksmipur Sadar, Laksmipur Sadar",
		status: "running",
		note: "",
		paymentInfo: "456 tk",
		deliveryType: "Cash on",
		lastUpdated: "",
		action: "...",
	},
	{
		orderDate: "Sept 17, 2022",
		customerName: "Sohag",
		parcelId: "434DFSDF34",
		invoiceId: "PARCEL#5354343",
		customerDetails: "samdani",
		phone: "01711993344",
		address: "Lata Laksmipur Sadar, Laksmipur Sadar",
		status: "completed",
		note: "",
		paymentInfo: "456 tk",
		deliveryType: "Cash on",
		lastUpdated: "",
		action: "...",
	},
];

const MerchantParcels = () => {
	const [dataRows, setDataRows] = useState(rows);
	const [filteredItem, setFilteredItem] = useState();

	//Merchant Data Table Filter Handler
	//Filter Table data based on status
	const handleStatusFilter = (status) => {
		setDataRows(
			rows.filter((item) => {
				return item.status.toLowerCase().includes(status.toLowerCase());
			})
		);
	};

	//search table data based on search string
	const handleGlobalSearch = (searchInput) => {
		// console.log(searchInput);
		const data = rows.filter((item) => {
			// return Object.values(item);

			const valArr = Object.values(item).filter((value) => {
				// console.log(value.toLowerCase().includes(searchInput.toLowerCase()));
				return value.toLowerCase().includes(searchInput.toLowerCase());
			});
			return valArr.length > 0 && item;
		});
		setDataRows(data);
	};

	return (
		<div style={{ backgroundColor: "#d9d8d9" }}>
			<DashboardHeader />
			<Container style={{ paddingTop: "100px", minHeight: "100vh" }}>
				<div className="data-table">
					<h2 style={{ fontWeight: "bold", fontSize: "35px" }}>All Orders</h2>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							gap: "60px",
							margin: "10px 0 25px 0",
						}}
					>
						{tableFilterData.map((item) => (
							<div
								key={item.key}
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "30px",
									backgroundColor: "#7030a0",
									padding: "10px 20px",
									borderRadius: "10px",
									color: "white",
									cursor: "pointer",
								}}
								onClick={() => {
									filteredItem === item.key
										? setFilteredItem("")
										: setFilteredItem(item.key);
									filteredItem === item.key
										? handleStatusFilter("")
										: handleStatusFilter(item.value);
								}}
							>
								<div
									className="check-circle"
									style={{
										width: "20px",
										height: "20px",
										borderRadius: "50%",
										backgroundColor:
											filteredItem === item.key ? "#45ff9c" : "white",
									}}
								></div>{" "}
								<span>{item.title}</span>
							</div>
						))}
					</div>
					<div
						className="global-search"
						style={{ display: "flex", paddingBottom: "20px" }}
					>
						<input
							type="text"
							name="search"
							id="search"
							onChange={(e) => handleGlobalSearch(e.target.value)}
							style={{
								width: "90%",
								maxWidth: "900px",
								padding: "8px 15px",
								fontSize: "18px",
								border: "2px solid #dbbaff",
								borderRadius: "8px",
							}}
							placeholder="search anything..."
						/>
						<button
							style={{
								fontSize: "18px",
								padding: "8px 18px",
								backgroundColor: "#7030a0",
								color: "#fff",
								borderRadius: "8px",
							}}
						>
							Search
						</button>
					</div>
					{/*<div className="status-filter">
						<label htmlFor="status">Status</label>
						<select
							name="status"
							id="status"
							onChange={(e) => handleStatusFilter(e.target.value)}
							defaultValue=""
						>
							<option value="" selected>
								All
							</option>
							<option value="pending">pending</option>
							<option value="On Going">On Going</option>
							<option value="Accepted">Accepted</option>
							<option value="Completed">Completed</option>
						</select>
					</div> */}
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							padding: "20px 0",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: "20px",
							}}
						>
							<span style={{ fontSize: "18px" }}>
								Total parcels: <b>{dataRows.length}</b>
							</span>
							<button style={tableBtnStyle}>
								<AiOutlineYoutube />{" "}
								<span>How to raise and track an issue</span>
							</button>
						</div>

						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: "20px",
							}}
						>
							<button style={tableBtnStyle}>
								<AiOutlinePrinter /> <span>Print Labels</span>
							</button>
							<button style={tableBtnStyle}>
								<AiOutlineDownload /> <span>Download Parcel History</span>
							</button>
						</div>
					</div>
					<Table
						columns={[
							{ key: "orderDate", title: "order date", dataIndex: "orderDate" },
							{
								key: "customerName",
								dataIndex: "customerName",
								title: "customer name",
							},
							{
								key: "parcelId",
								dataIndex: "parcelId",
								title: "Parcel ID",
								render: (id, { invoiceId }) => (
									<div>
										<p style={{ color: "blue" }}>
											<b>ID:</b> {id}{" "}
											<AiOutlineCopy
												style={{
													color: "#444444",
													fontSize: "20px",
													cursor: "pointer",
												}}
											/>
										</p>
										<p>
											<b>Invoice ID:</b> {invoiceId}
										</p>
									</div>
								),
							},
							{
								key: "customerDetails",
								dataIndex: "customerDetails",
								title: "customer details",
								render: (_, { phone, address }) => (
									<div>
										<p>{phone}</p>
										<p>{address}</p>
									</div>
								),
							},
							{ key: "status", dataIndex: "status", title: "status" },
							{
								key: "note",
								dataIndex: "note",
								title: "note",
								render: (_, { status }) => (
									<div>
										{status !== "completed" && (
											<span
												style={{
													backgroundColor: "#ffe9bf",
													padding: "5px",
													borderRadius: "10px",
													color: "#e08a00",
													fontWeight: "bold",
												}}
											>
												pickup pending
											</span>
										)}
									</div>
								),
							},
							{
								key: "paymentInfo",
								dataIndex: "paymentInfo",
								title: "payment info",
							},
							{
								key: "deliveryType",
								dataIndex: "deliveryType",
								title: "delivery type",
							},
							{
								key: "lastUpdated",
								dataIndex: "lastUpdated",
								title: "last updated",
							},
							{
								key: "action",
								dataIndex: "action",
								title: "Action",
								render: () => (
									<Dropdown
										overlay={
											<Menu
												items={[
													{
														label: <Button type="link">Edit</Button>,
														key: "0",
													},
													{
														label: <Button type="text">Raise An Issue</Button>,
														key: "1",
													},
													{
														label: (
															<Button type="text" danger>
																Delete
															</Button>
														),
														key: "2",
													},
												]}
											/>
										}
										trigger={["click"]}
									>
										<Button type="text">
											<BsThreeDots
												style={{
													fontSize: "25px",
												}}
											/>
										</Button>
									</Dropdown>
								),
							},
						]}
						dataSource={dataRows}
					/>
				</div>
			</Container>
		</div>
	);
};
const tableBtnStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "5px",
	color: "#7c00db",
	border: "none",
	background: "transparent",
	fontSize: "18px",
	cursor: "pointer",
};
export default MerchantParcels;
