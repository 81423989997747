import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";

//import Icons
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

//import images
// import footerMascot from "../images/footer/1 Mascot 1.png";
import imgBariKoi from "../../images/footer/Barikoi logo.png";
import imgCilt from "../../images/footer/CILT White.png";
import imgIct from "../../images/footer/ICT White.png";
import imgStartup from "../../images/footer/Startup BD White.png";
import rapidoWhite from "../../images/Logo Png 2 (White).png";
import mascot from "../../images/banner/mascot.png";
import termsAndCondition from "../../docs/TermsAndConditions.pdf";
import { Container, Hidden, useScreenClass } from "react-grid-system";

const Footer = () => {
	const screenClass = useScreenClass();
	return (
		<footer
			id="footer-full"
			className="footer-full"
			style={{
				width: "100%",
				// height: ["lg", "xl", "xxl"].includes(screenClass) && "90vh",
			}}
		>
			<div className="footer-top" style={{ padding: "40px 0" }}>
				<Container>
					<h2
						style={{
							color: "white",
							fontSize: "40px",
							textAlign: "center",
							padding: "30px 0",
						}}
					>
						Supported by:
					</h2>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							maxWidth: "1000px",
							width: "100%",
							margin: "0 auto",
						}}
					>
						<img src={imgIct} alt="ICT division" style={{ width: "11%" }} />
						<img src={imgCilt} alt="CILT" style={{ width: "13%" }} />
						<img src={imgBariKoi} alt="Bari Koi" style={{ width: "13%" }} />
						<img src={imgStartup} alt="startup" style={{ width: "15%" }} />
					</div>
				</Container>
			</div>
			<div className="footer-bottom">
				<Container>
					<div
						style={{
							display: ["sm", "xs", "md"].includes(screenClass) && "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img
							src={rapidoWhite}
							alt="rapido white"
							style={{
								width: ["lg", "xl", "xxl"].includes(screenClass)
									? "20%"
									: "70%",
								marginLeft: ["lg", "xl", "xxl"].includes(screenClass)
									? "-55px"
									: "0",
							}}
						/>
					</div>
					<div
						className="footer-middle"
						style={{
							paddingTop: "40px",
							display: "flex",
							alignItems: ["xs", "sm"].includes(screenClass)
								? "center"
								: "flex-start",
							justifyContent: "space-between",
							color: "white",
							flexDirection: ["xs", "sm"].includes(screenClass)
								? "column"
								: "row",
							position: "relative",
						}}
					>
						<Hidden sm xs md>
							<img
								src={mascot}
								alt="mascot"
								style={{
									position: "absolute",
									right: "-190px",
									bottom: "90px",
									width: "13%",
								}}
							/>
						</Hidden>
						<div
							className="middle-item"
							style={{
								width: ["lg", "xl", "xxl"].includes(screenClass)
									? "19%"
									: "100%",
								textAlign: ["lg", "xl", "xxl"].includes(screenClass)
									? "left"
									: "center",
							}}
						>
							<h4>Company</h4>
							<ul>
								<li>
									<Link to="/">About us</Link>
								</li>
								<li>
									<Link to="/">Coverage Areas</Link>
								</li>
								<li>
									<Link to="/">FAQs</Link>
								</li>
							</ul>
						</div>
						<div
							className="middle-item"
							style={{
								width: ["lg", "xl", "xxl"].includes(screenClass)
									? "19%"
									: "100%",
								textAlign: ["lg", "xl", "xxl"].includes(screenClass)
									? "left"
									: "center",
							}}
						>
							<h4>Join with us</h4>
							<ul>
								<li>
									<Link to="/">Driver Partners</Link>
								</li>
								<li>
									<Link to="/">Merchant Partners</Link>
								</li>
							</ul>
						</div>
						<div
							className="middle-item"
							style={{
								width: ["lg", "xl", "xxl"].includes(screenClass)
									? "19%"
									: "100%",
								textAlign: ["lg", "xl", "xxl"].includes(screenClass)
									? "left"
									: "center",
							}}
						>
							<h4>Contact us</h4>
							<ul>
								<li>
									<Link to="/">Head Office</Link>
								</li>
								<li>
									<a href="https://www.barikoi.com/search">
										Holding 90, Road 4, Block D Mohanogor Project, Hatirjheel.
									</a>
									<br />
									<p>
										Email:{" "}
										<a href="mailto:info@rapido.com.bd">info@rapido.com.bd</a>
									</p>
									<br />
									<p>
										HOTLINE <a href="tel:09613410510">09613410510</a>
									</p>
								</li>
							</ul>
						</div>
						<div
							className="middle-item"
							style={{
								width: ["lg", "xl", "xxl"].includes(screenClass)
									? "19%"
									: "100%",
								textAlign: ["lg", "xl", "xxl"].includes(screenClass)
									? "left"
									: "center",
							}}
						></div>
						<div
							className="middle-item"
							style={{
								width: ["lg", "xl", "xxl"].includes(screenClass)
									? "19%"
									: "100%",
								textAlign: ["lg", "xl", "xxl"].includes(screenClass)
									? "left"
									: "center",
								paddingTop: "60px",
							}}
						>
							<h4>Connect with us</h4>
							<ul
								className="social-link"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: ["xl", "lg", "xxl"].includes(screenClass)
										? "flex-start"
										: "center",
									gap: ["xl", "lg", "xxl"].includes(screenClass)
										? "40px"
										: "15px",
								}}
							>
								<li>
									<a href="https://www.facebook.com/rapido.deliveries/">
										<FaFacebookF
											className="icon"
											// style={{ color: "white", fontSize: "25px" }}
										/>
									</a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/rapido-deliveries">
										<FaLinkedin className="icon" />
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/rapido.deliveries/">
										<RiInstagramFill className="icon" />
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="footer-bottom" style={{ padding: "30px 0" }}>
						<div
							className="bottom-top"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: ["xl", "lg", "xxl"].includes(screenClass)
									? "space-between"
									: "center",
								gap: "15px",
							}}
						>
							<div
								className="top-item"
								style={{
									width: ["sm", "xs", "md"].includes(screenClass)
										? "100%"
										: "18%",
								}}
							>
								<a
									href="/privacy-policy"
									style={{ textDecoration: "underline" }}
								>
									Privacy Policy
								</a>
								<br />
								<br />
								<a href="/">API Support</a>
							</div>
							<div className="top-item">
								<a
									href={termsAndCondition}
									style={{ textDecoration: "underline" }}
									target="_blank"
									rel="noreferrer"
								>
									Terms & Conditions
								</a>
							</div>
						</div>
						<div
							className="bottom-bottom"
							style={{ paddingTop: "20px", color: "#ededed", fontSize: "15px" }}
						>
							<p>
								&copy; 2022 Rapido is registered in the Directorate of
								Intellectual Property of the Republic of Bangladesh
							</p>
						</div>
					</div>
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
