import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Login, Registration } from "./components";
import MerchantPanel from "./components/Dashboard/Merchant/MerchantPanel";
import AdminPanel from "./components/Dashboard/Admin/AdminPanel";
import MerchantParcels from "./components/Dashboard/Merchant/MerchantParcels";
import EcomRegister from "./components/Auth/EcomRegister";
import PrivacyPolicy from "./components/Utilities/PrivacyPolicy";
import UserProfile from "./components/UserProfile";
import PrivateOutlet from "./components/Utilities/PrivateOutlet";
import { useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import "antd/dist/antd.css";
import CreateParcel from "./components/Dashboard/Merchant/CreateParcel/CreateParcel";
import ImportStatus from "./components/Dashboard/Merchant/CreateParcel/ImportStatus";

function App() {
	const isValidating = useSelector((state) => state.auth.isValidating);

	return (
		<div>
			{isValidating ? (
				<div>
					<h2>Loading</h2>
					<FaSpinner />
				</div>
			) : (
				<BrowserRouter>
					<Routes>
						<Route path="/" exact element={<Home />} />
						<Route path="/register">
							<Route index element={<Registration />} />
							<Route path="e-com" element={<EcomRegister />} />
						</Route>
						<Route path="/login" element={<Login />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/*" element={<PrivateOutlet />}>
							<Route path="admin">
								<Route index element={<AdminPanel />} />
								<Route path="user-profile" element={<UserProfile />} />
							</Route>
							<Route path="merchant">
								<Route path="dashboard" element={<MerchantPanel />} />
								<Route path="create-parcel" element={<CreateParcel />} />
								<Route path="import-status" element={<ImportStatus />} />
								<Route path="user-profile" element={<UserProfile />} />
								<Route path="parcels" element={<MerchantParcels />} />
							</Route>
						</Route>
					</Routes>
				</BrowserRouter>
			)}
		</div>
	);
}

export default App;
