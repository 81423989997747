import React from "react";
import AppStore from "./Landing/AppStore";
import Banner from "./Landing/Banner";
import Coverage from "./Landing/Coverage";
import Delivery from "./Landing/Delivery";
import Footer from "./Landing/Footer";
import Layout from "./Layout";
import OurClients from "./Landing/OurClients";
import Pricing from "./Landing/Pricing";
import Review from "./Landing/Review";
import Services from "./Landing/Services";
import Story from "./Landing/Story";

const Home = () => {
	return (
		<Layout>
			<Banner />
			<Services />
			<Coverage />
			<Pricing />
			<Delivery />
			<Review />
			<Story />
			<AppStore />
			<OurClients />
			<Footer />
		</Layout>
	);
};

export default Home;
